import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
} from '@chakra-ui/react'
import {
  FiHome,
  FiTrendingUp,
  FiStar,
  FiMenu,
  FiDollarSign,
  FiChevronDown,
  FiChevronsUp, 
  FiZap,
  FiUmbrella
} from 'react-icons/fi'
import { IoPricetagOutline } from "react-icons/io5";
import { MdOutlineSupportAgent } from "react-icons/md";

import { Link } from 'react-router-dom';


const LinkItems = [
  { name: 'Overview', icon: FiHome, link: "/dashboard/overview" },
  { name: 'Usage', icon: FiTrendingUp, link: "/dashboard/api_usage" },
  { name: 'Pricing', icon: IoPricetagOutline, link: "/dashboard/pricing" },
  { name: 'Billing', icon: FiDollarSign, link: "/dashboard/billings" },
  { name: 'gRPC', icon: FiZap, link: "/dashboard/grpc" },
  { name: 'Dedicated Nodes', icon: FiChevronsUp, link: "/dashboard/dedicated-nodes" },
  { name: 'Jup API', icon: FiUmbrella, link: "/dashboard/jup_api" },
  { name: "SPACER", icon: null, link: null },
  { name: 'Resources', icon: FiStar, link: "/dashboard/resources" },
  { name: 'Support', icon: MdOutlineSupportAgent, link: "/dashboard/support" }
]

const SidebarContent = ({ onClose,path, ...rest }) => {
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('black', 'black')}
      borderRight="1px"
      borderRightColor={useColorModeValue('brand.lightGrey', 'brand.lightGrey')}
      w={{ base: 'full', md: 60, lg: "280px" }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="14" justifyContent="space-between">
        <Box as='a' href='/'>
            <Image src={"/shyft_logo.svg"} w={"92px"} alt='Shyft logo'/>
        </Box>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} color={"brand.white"}/>
      </Flex>
      <Box h={"20px"}>

      </Box>
      {LinkItems.map((link) => {
        if(link.name === "SPACER"){
          return (
            <Box h={{base: "15%", md: "25%"}}>

            </Box>
          )
        }
        return (
        <NavItem key={link.name} icon={link.icon} link={link.link} path={path}>
          {link.name}
        </NavItem>
      )})}
    </Box>
  )
}

const NavItem = ({ icon, children, link, path, ...rest }) => {
  return (
    <Box
      fontSize={{base: "md", lg: "lg"}}
      fontFamily={"heading"}
      fontWeight={"500"}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}>
      <Link to={link}>
        <Flex
          align="center"
          p="4"
          mx="10"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          color={(link.includes(path))?"brand.yellow":"brand.white"}
          _hover={{
            bg: 'brand.red',
            color: 'white',
          }}
          {...rest}>
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              color={(link.includes(path))?"brand.red":"brand.white"}
              _groupHover={{
                color: 'white',
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Link>
    </Box>
  )
}

const MobileNav = ({ onOpen,email,logout,name,apKey, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('black', 'black')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('brand.lightGrey', 'brand.lightGrey')}
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
        color={"brand.white"}
        _hover={{
          bg: "transparent"
        }}
      />

      {/* <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold">
        Logo
      </Text> */}
      <Box 
        display={{ base: 'flex', md: 'none' }}
        as='a'
        href='/'
      >
            <Image src={"/shyft_logo.svg"} w={"90px"} alt='Shyft logo'/>
      </Box>

      <HStack spacing={{ base: '0', md: '6' }}>
        {/* <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} /> */}
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  size={'sm'}
                  name={email}
                  src={
                    ''
                  }
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text as={"div"} fontSize="sm" color={useColorModeValue("brand.white","brand.white")}>{(name !== '')?name:email}</Text>
                  <Text as={"div"} fontSize="xs" color="brand.midGrey">
                    {(name !== '')?email:apKey}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              {/* <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuItem>Billing</MenuItem>
              <MenuDivider /> */}
              <MenuItem onClick={logout}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  )
}

const SidebarWithHeader = ({ children,path,email,name,apKey,logout }) => {
  // const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box minH="100vh" bg={useColorModeValue('brand.black', 'brand.black')}>
      <SidebarContent path={path} onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size={{base: "xs", md: "full"}}>
        <DrawerContent>
          <SidebarContent path={path} onClose={onClose}/>
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} email={email} name={name} apKey={apKey} logout={logout}/>
      <Box ml={{ base: 0, md: 60, lg: "280px" }} p="4">
        {children}
      </Box>
    </Box>
  )
}

export default SidebarWithHeader
import { useEffect, useState } from 'react';
import { useRive } from '@rive-app/react-canvas';
import { Container, Stack, Flex, Box, Heading, Text, Image, createIcon, Link } from '@chakra-ui/react';
// import { inView, useInView } from "framer-motion";
import { useInView } from 'react-intersection-observer';
// import { Link } from '@chakra-ui/next-js';

export default function FeatureSection() {
    const [nftImageLoaded, setNftLoaded] = useState(false);
    const [rpcImageLoaded, setRpcLoaded] = useState(false);
    const [callbackImageLoaded, setCallbackLoaded] = useState(false);

    const NFTRive = useRive({
        src: '/assets/Animations/nft_apis_anim.riv',
        animations: 'Timeline 1',
        autoplay: false,
    });
    const RPCRive = useRive({
        src: '/assets/Animations/rpc_image.riv',
        animations: 'Timeline 1',
        autoplay: false,
    });
    const callbackRive = useRive({
        src: '/assets/Animations/Callbacks_file.riv',
        animations: 'Timeline 1',
        autoplay: false,
    });

    const NFTImageContainer = useInView({
        threshold: 0,
        delay: 1,
    });
    useEffect(() => {
        if (NFTImageContainer.inView === true) {
            if (nftImageLoaded === false && NFTRive.rive) {
                if (NFTRive.rive.isPlaying) NFTRive.rive.stop('Timeline 1');

                NFTRive.rive.play('Timeline 1');
                setNftLoaded(true);
            }
        }
    }, [NFTImageContainer.inView, NFTRive.rive]);

    const RPCImageContainer = useInView({
        threshold: 0,
        delay: 1,
    });
    useEffect(() => {
        // console.log("The RPc ANImation Viewport in view:",RPCImageContainer.inView)
        if (RPCImageContainer.inView === true) {
            if (rpcImageLoaded === false && RPCRive.rive) {
                if (RPCRive.rive.isPlaying) RPCRive.rive.stop('Timeline 1');

                RPCRive.rive.play('Timeline 1');
                setRpcLoaded(true);
            }
        }
    }, [RPCImageContainer.inView, RPCRive.rive]);

    const callBackImageContainer = useInView({
        threshold: 0,
        delay: 1,
    });
    useEffect(() => {
        // console.log("The callback ANImation Viewport in view:",callBackImageContainer.inView)
        if (callBackImageContainer.inView === true) {
            if (callbackImageLoaded === false && callbackRive.rive) {
                if (callbackRive.rive.isPlaying) callbackRive.rive.stop('Timeline 1');

                callbackRive.rive.play('Timeline 1');
                setCallbackLoaded(true);
            }
        }
    }, [callBackImageContainer.inView, callbackRive]);

    return (
        <>
            <Stack as="div" bg={'#111'}>
                <Container maxW={'7xl'}>
                    <Stack
                        align={'center'}
                        spacing={{ base: 8, md: 10 }}
                        pt={{ base: 10, md: 16 }}
                        pb={{ base: 10, md: 0 }}
                        direction={{ base: 'column', md: 'row' }}
                        id='solana-rpc-details'
                    >
                        <Stack flex={1} order={{ base: 2, md: 1 }} spacing={{ base: 2, md: 6 }}>
                            <Stack>
                                <Text as={'span'} color={'brand.yellow'}>
                                    Dependable + Fast
                                </Text>
                                <Heading
                                    lineHeight={1.1}
                                    fontWeight={600}
                                    fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
                                    mb={0}
                                    color={'brand.white'}
                                >
                                    Solana RPCs
                                </Heading>
                            </Stack>

                            <Text as={"div"} color={'brand.grey'} fontSize={{ base: 'xs', md: 'md' }}>
                                Experience the best-in-class RPCs powering hundreds of developers on Solana. Optimized
                                for uptime and speed.
                            </Text>
                            <Stack
                                spacing={{ base: 4, sm: 6 }}
                                direction={{ base: 'column', sm: 'row' }}
                                pt={{ base: '12px', md: '0px' }}
                            >
                                {/* <Button
                                    rounded={'full'}
                                    fontWeight={'semi-bold'}
                                    fontFamily={'heading'}
                                    fontSize={'sm'}
                                    px={'12'}
                                    py={'1'}
                                    bg={'brand.red'}
                                    color={"brand.white"}
                                    _hover={{
                                        bg: 'brand.white',
                                        color: "brand.red"
                                    }}
                                >
                                    Know More
                                </Button> */}
                                <Link
                                    href={'https://docs.shyft.to/solana-rpcs-das-api/shyft-rpcs'}
                                    target='_blank'
                                    rounded={'full'}
                                    w={{ base: '120px', md: '160px' }}
                                    textAlign={'center'}
                                    fontFamily={'heading'}
                                    fontSize={{ base: 'xs', md: 'sm' }}
                                    lineHeight={{ base: 'xs', md: 'sm' }}
                                    bgColor={'brand.red'}
                                    color={'brand.white'}
                                    pt={{ base: '5px', md: '7px' }}
                                    pb={{ base: '6px', md: '8px' }}
                                    _hover={{
                                        bgColor: 'brand.white',
                                        color: 'brand.red',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Know More
                                </Link>
                                {/* <Button
                                    rounded={'full'}
                                    variant={'outline'}
                                    fontWeight={'semi-bold'}
                                    fontFamily={'heading'}
                                    fontSize={'sm'}
                                    color={'white'}
                                    border={'2px solid'}
                                    borderColor={'brand.red'}
                                    px={'12'}
                                    py={'1'}
                                    _hover={{
                                        bg: "brand.red",
                                        color: "brand.white"
                                    }}
                                >
                                    Dev Docs
                                </Button> */}
                            </Stack>
                        </Stack>
                        <Flex
                            flex={1}
                            order={{ base: 1, md: 2 }}
                            justify={'center'}
                            align={'center'}
                            position={'relative'}
                            w={'full'}
                        >
                            <Box
                                position={'relative'}
                                rounded={'2xl'}
                                width={'full'}
                                overflow={'hidden'}
                                ref={RPCImageContainer.ref}
                            >
                                {/* <Image
                                    alt={'Hero Image'}
                                    fit={'contain'}
                                    align={'center'}
                                    w={'80%'}
                                    aspectRatio={3 / 2}
                                    src={'/assets/Animations/rpc_anim_once.gif'}
                                    margin={'0 auto'}
                                /> */}
                                <Box className="square-canvas">
                                    <RPCRive.RiveComponent />
                                </Box>
                            </Box>
                        </Flex>
                    </Stack>
                </Container>
            </Stack>
            {/* Inverse Section */}
            <Stack as="div" bg={'#111'}>
                <Container maxW={'7xl'}>
                    <Stack
                        align={'center'}
                        spacing={{ base: 8, md: 10 }}
                        py={{ base: 10, md: 16 }}
                        direction={{ base: 'column', md: 'row' }}
                    >
                        <Flex flex={1} justify={'center'} align={'center'} position={'relative'} w={'full'}>
                            <Box
                                position={'relative'}
                                rounded={'2xl'}
                                width={'full'}
                                overflow={'hidden'}
                                ref={callBackImageContainer.ref}
                            >
                                {/* <Image
                                    alt={'Hero Image'}
                                    fit={'contain'}
                                    align={'center'}
                                    w={'90%'}
                                    aspectRatio={3 / 2}
                                    src={'/assets/Animations/callback_image.gif'}
                                /> */}
                                <Box className="super-canvas">
                                    <callbackRive.RiveComponent />
                                </Box>
                            </Box>
                        </Flex>
                        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                            <Stack>
                                <Text as={'span'} color={'brand.yellow'}>
                                    Lowest Latencies
                                </Text>
                                <Heading
                                    lineHeight={1.1}
                                    fontWeight={600}
                                    fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
                                    mb={0}
                                >
                                    <Text as={'span'} color={'brand.white'}>
                                        Callbacks & gRPC streaming
                                    </Text>
                                </Heading>
                            </Stack>
                            <Stack>
                                <Text as={"div"} color={'brand.grey'} fontSize={{ base: 'xs', md: 'md' }}>
                                    {/* Receive Solana transactions and account updates, straight to your backend as they
                                    happen on-chain. Parsed, annotated and summarized. */}
                                    Receive Solana transactions, account and blocks updates, straight to your backend. Parsed and summarized through webhooks or raw from gRPC.
                                </Text>
                            </Stack>

                            <Stack spacing={{ base: 4, sm: 6 }} direction={{ base: 'column', sm: 'row' }}>
                                <Link
                                    href={'https://docs.shyft.to/start-hacking/callbacks'}
                                    target="_blank"
                                    rounded={'full'}
                                    w={{ base: '120px', md: '160px' }}
                                    textAlign={'center'}
                                    fontFamily={'heading'}
                                    fontSize={{ base: 'xs', md: 'sm' }}
                                    lineHeight={{ base: 'xs', md: 'sm' }}
                                    bgColor={'brand.red'}
                                    color={'brand.white'}
                                    pt={{ base: '5px', md: '7px' }}
                                    pb={{ base: '6px', md: '8px' }}
                                    _hover={{
                                        bgColor: 'brand.white',
                                        color: 'brand.red',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Callback Docs
                                </Link>
                                <Link
                                    href={'https://docs.shyft.to/solana-grpc-shredstream/grpc-docs'}
                                    target="_blank"
                                    rounded={'full'}
                                    w={{ base: '120px', md: '160px' }}
                                    textAlign={'center'}
                                    fontFamily={'heading'}
                                    fontSize={{ base: 'xs', md: 'sm' }}
                                    lineHeight={{ base: 'xs', md: 'sm' }}
                                    bgColor={'brand.red'}
                                    color={'brand.white'}
                                    pt={{ base: '5px', md: '7px' }}
                                    pb={{ base: '6px', md: '8px' }}
                                    _hover={{
                                        bgColor: 'brand.white',
                                        color: 'brand.red',
                                        textDecoration: 'none',
                                    }}
                                >
                                    gRPC Docs
                                </Link>
                                {/* <Button
                                    rounded={'full'}
                                    fontWeight={'semi-bold'}
                                    fontFamily={'heading'}
                                    fontSize={'sm'}
                                    px={'12'}
                                    py={'1'}
                                    bg={'brand.red'}
                                    color={"brand.white"}
                                    _hover={{
                                        bg: 'brand.white',
                                        color: "brand.red"
                                    }}
                                >
                                    Know More
                                </Button> */}
                                {/* <Button
                                    rounded={'full'}
                                    variant={'outline'}
                                    fontWeight={'semi-bold'}
                                    fontFamily={'heading'}
                                    fontSize={'sm'}
                                    color={'white'}
                                    border={'2px solid'}
                                    borderColor={'brand.red'}
                                    px={'12'}
                                    py={'1'}
                                    _hover={{
                                        bg: "brand.red",
                                        color: "brand.white"
                                    }}
                                >
                                    Dev Docs
                                </Button> */}
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
            </Stack>


            {/* Normal Section */}
            <Stack as="div" bg={'#111'}>
                <Container maxW={'7xl'}>
                    <Stack
                        align={'center'}
                        spacing={{ base: 8, md: 10 }}
                        pt={{ base: 10, md: 28 }}
                        pb={{ base: 20, md: 40 }}
                        direction={{ base: 'column', md: 'row' }}
                    >
                        
                        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                            <Stack>
                                <Text as={'span'} color={'brand.yellow'}>
                                    Reliably Parsed
                                </Text>
                                <Heading
                                    lineHeight={1.1}
                                    fontWeight={600}
                                    fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
                                    color={'brand.white'}
                                    mb={0}
                                >
                                    {/* <Text as={'span'} color={'brand.white'}> */}
                                    Readable Transactions
                                    {/* </Text> */}
                                </Heading>
                            </Stack>
                            <Stack>
                                {/* <Heading as={"h3"} color={'brand.white'} fontWeight={"500"} fontSize={{base: "2xl", md:"3xl"}}>
                                    Fast Reads, Reliable Writes
                                </Heading> */}
                                <Text as={"div"} color={'brand.grey'} fontSize={{ base: 'xs', md: 'md' }}>
                                    Raw transactions parsed into a format that finally makes sense. Analysing Solana
                                    transactions was never this easy. NFT, Defi, or DAOs, we have the widest coverage. History limited to past 4-5 days.
                                </Text>
                            </Stack>

                            <Stack spacing={{ base: 4, sm: 6 }} direction={{ base: 'column', sm: 'row' }}>
                                <Link
                                    href={'https://docs.shyft.to/start-hacking/transactions'}
                                    target="_blank"
                                    rounded={'full'}
                                    w={{ base: '120px', md: '160px' }}
                                    textAlign={'center'}
                                    fontFamily={'heading'}
                                    fontSize={{ base: 'xs', md: 'sm' }}
                                    lineHeight={{ base: 'xs', md: 'sm' }}
                                    bgColor={'brand.red'}
                                    color={'brand.white'}
                                    pt={{ base: '5px', md: '7px' }}
                                    pb={{ base: '6px', md: '8px' }}
                                    _hover={{
                                        bgColor: 'brand.white',
                                        color: 'brand.red',
                                        textDecoration: 'none',
                                    }}
                                >
                                    Know More
                                </Link>
                                {/* <Button
                                    rounded={'full'}
                                    fontWeight={'semi-bold'}
                                    fontFamily={'heading'}
                                    fontSize={'sm'}
                                    px={'12'}
                                    py={'1'}
                                    bg={'brand.red'}
                                    color={"brand.white"}
                                    _hover={{
                                        bg: 'brand.white',
                                        color: "brand.red"
                                    }}
                                >
                                    Know More
                                </Button>
                                <Button
                                    rounded={'full'}
                                    variant={'outline'}
                                    fontWeight={'semi-bold'}
                                    fontFamily={'heading'}
                                    fontSize={'sm'}
                                    color={'white'}
                                    border={'2px solid'}
                                    borderColor={'brand.red'}
                                    px={'12'}
                                    py={'1'}
                                    _hover={{
                                        bg: "brand.red",
                                        color: "brand.white"
                                    }}
                                >
                                    Dev Docs
                                </Button> */}
                            </Stack>
                        </Stack>
                        <Flex flex={1} justify={'center'} align={'center'} position={'relative'} w={'full'}>
                            <Box position={'relative'} rounded={'2xl'} width={'full'} overflow={'hidden'} ps={{ base: 0, md: "70px" }}>
                                <Image
                                    alt={'Hero Image'}
                                    fit={'contain'}
                                    align={'center'}
                                    w={'86%'}
                                    aspectRatio={3 / 2}
                                    src={'/assets/Animations/parser_anim_once.gif'}
                                />
                            </Box>
                        </Flex>
                    </Stack>
                </Container>
            </Stack>
        </>
    );
}

const PlayIcon = createIcon({
    displayName: 'PlayIcon',
    viewBox: '0 0 58 58',
    d: 'M28.9999 0.562988C13.3196 0.562988 0.562378 13.3202 0.562378 29.0005C0.562378 44.6808 13.3196 57.438 28.9999 57.438C44.6801 57.438 57.4374 44.6808 57.4374 29.0005C57.4374 13.3202 44.6801 0.562988 28.9999 0.562988ZM39.2223 30.272L23.5749 39.7247C23.3506 39.8591 23.0946 39.9314 22.8332 39.9342C22.5717 39.9369 22.3142 39.8701 22.0871 39.7406C21.86 39.611 21.6715 39.4234 21.5408 39.1969C21.4102 38.9705 21.3421 38.7133 21.3436 38.4519V19.5491C21.3421 19.2877 21.4102 19.0305 21.5408 18.8041C21.6715 18.5776 21.86 18.3899 22.0871 18.2604C22.3142 18.1308 22.5717 18.064 22.8332 18.0668C23.0946 18.0696 23.3506 18.1419 23.5749 18.2763L39.2223 27.729C39.4404 27.8619 39.6207 28.0486 39.7458 28.2713C39.8709 28.494 39.9366 28.7451 39.9366 29.0005C39.9366 29.2559 39.8709 29.507 39.7458 29.7297C39.6207 29.9523 39.4404 30.1391 39.2223 30.272Z',
});

import { Box, Stack, Heading, Text } from '@chakra-ui/react';
import Marquee from 'react-fast-marquee';
import RollingLine from '../../common/RollingLine';

export default function UtilityRollingSection() {
    return (
        <>
            <Stack as={'div'} backgroundColor={'#111'} transform={'rotate(-2deg)'} marginTop={'-36px'} zIndex={4}>
                <RollingLine />
                <Stack as={'div'} width={{ base: '103vw', md: '101vw' }} marginLeft={'-4px'} marginBottom={'6px'}>
                    <Box as={'div'} backgroundColor="brand.red" padding={'40px 0px 28px'}>
                        <Box paddingBottom={{ base: '12px', md: '24px' }}>
                            <Text as={"div"} textAlign={'center'} fontSize={{ base: 'xs', md: 'md' }} color={'#fff'}>
                                Build Faster and Smarter
                            </Text>
                            <Heading
                                as={'h2'}
                                textAlign={'center'}
                                fontSize={{ base: '3xl', xl: '5xl' }}
                                mb={{base: "0px"}}
                                color={'#fff'}
                            >
                                Complete Development Suite
                            </Heading>
                        </Box>

                        <Marquee>
                            <Box as="div" className="rolling_each_text">
                                Low-Latency Streaming
                            </Box>
                            <Box as="div" className="rolling_each_text">
                                Callbacks
                            </Box>
                            <Box as="div" className="rolling_each_text">
                                Jup API
                            </Box>
                            <Box as="div" className="rolling_each_text">
                                High-Speed RPCs
                            </Box>
                            <Box as="div" className="rolling_each_text">
                                gRPC Streams
                            </Box>
                            <Box as="div" className="rolling_each_text">
                                Parsed Transactions
                            </Box>
                            <Box as="div" className="rolling_each_text">
                                Dedicated Nodes
                            </Box>

                            <Box as="div" className="rolling_each_text">
                                Low-Latency Streaming
                            </Box>
                            <Box as="div" className="rolling_each_text">
                                Callbacks
                            </Box>
                            <Box as="div" className="rolling_each_text">
                                Jup API
                            </Box>
                            <Box as="div" className="rolling_each_text">
                                High-Speed RPCs
                            </Box>
                            <Box as="div" className="rolling_each_text">
                                gRPC Streams
                            </Box>
                            <Box as="div" className="rolling_each_text">
                                Parsed Transactions
                            </Box>
                            <Box as="div" className="rolling_each_text">
                                Dedicated Nodes
                            </Box>
                        </Marquee>
                    </Box>
                </Stack>
                <RollingLine />
            </Stack>
            <Stack as={'div'} backgroundColor={'#111'} height={'64px'} marginTop={'-35px'} width={'100vw'}></Stack>
        </>
    );
}

import { useState, useEffect } from "react";
import { ReactSession } from "react-client-session";
// import { useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import {
  Box,
  Text,
  Stack,
  Flex,
  Button,
  HStack,
  Container,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import MainStyles from "../../resources/css/css.module.css";
import LoadingStyles from "../../resources/css/loadingAnime.module.css";
import { BeatLoader } from 'react-spinners';
import { FaCheck } from "react-icons/fa";
import { ImCross } from "react-icons/im";


const Billings = (props) => {
    // const stripe = useStripe();
    // const backendStripe = new Stripe('',{
    //     apiVersion:"2022-11-15",
    // });
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [isLoading, setLoading] = useState(true);
    const [errOcc, setErrOcc] = useState(false);
    const [data, setData] = useState([]);
    const [userCards, setUserCards] = useState([]);
    const [cardCancelled,setCardCancelled] = useState('');
    const [planCancelled,setPlanCancelled] = useState('');
    const [planCancelName,setPlanCancelName] = useState('');
    const [cancelStatus,setCancelStatus] = useState("NO_ACTION");

    const [errorWhileCancelPlan, setErrorWhileCancelPlan] = useState(false);

    const [showCancelCard,setShowCancelCard] = useState(false);
    const [gRPCExpiry,setgRPCExpiry] = useState('');

    

    useEffect(() => {
        const token = ReactSession.get("xTkn") ?? "";
        if (token !== "") {
            const endPoint = process.env.REACT_APP_AUTH_EP;
            axios({
                url: `${endPoint}dashboard/card`,
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "ngrok-skip-browser-warning": "69420",
                }
            })
                .then((res) => {
                    if (res.data.success === true && res.data.result.length > 0) {
                        const cards = [];
                        cards.push(res.data.result[0]);
                        setUserCards(cards);
                    }
                })
                // Catch errors if any
                .catch((err) => {
                    console.warn(err);
                    
                });
        }
    }, [props.isFreeUser])

    /*
    const removeCard = (id) => {
        setCardCancelled(id);
        // document.getElementById("remLoad").disabled = true;
        // document.getElementById("remLoad").innerHTML = `<div class=${LoadingStyles.lds_ripple}><div></div><div></div></div>`;
        const endPoint = process.env.REACT_APP_AUTH_EP;
        const token = ReactSession.get("xTkn") ?? "";
        axios({
            url: `${endPoint}dashboard/card?payment_method_id=${id}`,
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
                "ngrok-skip-browser-warning": "69420",
            }
        })
            .then((res) => {
                if (res.data.success === true) {
                    setUserCards([]);
                    props.setCurrentPlan("FREE");
                    ReactSession.set("ifr", "FREE");
                }
                else {
                    document.getElementById("remLoad").innerHTML = "Cancel Plan";
                    document.getElementById("remLoad").disabled = false;
                }
            })
            // Catch errors if any
            .catch((err) => {
                console.warn(err);
                document.getElementById("remLoad").innerHTML = "Cancel Plan";
                document.getElementById("remLoad").disabled = false;
                
            });
    }
    */

    
    const cancelPlanNow = (id) => {
        setCancelStatus("CANCELLING");
        const endPoint = process.env.REACT_APP_AUTH_EP;
        const token = ReactSession.get("xTkn") ?? "";
        axios({
            url: `${endPoint}dashboard/cancel_plan`,
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                "ngrok-skip-browser-warning": "69420",
            },
            data: {
                plan_id: id
            }
        })
            .then((res) => {
                
                if (res.data.success === true) {

                    const planBeingCancelled = data.find(plan => plan.plan_id === id);
                    const currentPlans = data.filter(plan => plan.plan_id !== id);
                    planBeingCancelled.cancel_at_cycle_end = true;
                    currentPlans.push(planBeingCancelled);
                    setData(currentPlans);
                    setCancelStatus("CANCELLED");
                    setPlanCancelled('');
                    setTimeout(() => {
                        setCancelStatus("NO_ACTION");
                        setShowCancelCard(false);
                    }, 1000);
                    // props.setCurrentPlan("FREE");
                    // ReactSession.set("ifr", "FREE");
                }
                else {
                    setErrorWhileCancelPlan(true);
                    setPlanCancelled('');
                    setCancelStatus("NOT_CANCELLED");
                    setTimeout(() => {
                        setCancelStatus("NO_ACTION");
                        setShowCancelCard(false);
                    }, 1000);
                }
            })
            // Catch errors if any
            .catch((err) => {
                console.warn(err);
                setErrorWhileCancelPlan(true);
                setPlanCancelled('');
                setCancelStatus("NOT_CANCELLED");
                setTimeout(() => {
                    setCancelStatus("NO_ACTION");
                    setShowCancelCard(false);
                }, 1000);
            });
    }
    


    // useEffect(() => {
    //     const token = ReactSession.get("xTkn") ?? "";
    //     const isFree = ReactSession.get("ifr") ?? "unknown";
    //     if (isFree === "Free")
    //         props.setFreeUser(true)
    //     else
    //         props.setFreeUser(false)
    //     if (token !== "") {
    //         const endPoint = process.env.REACT_APP_AUTH_EP;
    //         axios({
    //             url: `${endPoint}dashboard/billing`,
    //             method: "GET",
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //                 "ngrok-skip-browser-warning": "69420",
    //             }
    //         })
    //             .then((res) => {
    //                 if (res.data.success === true) {
    //                     setData(res.data.result);
    //                 }
    //                 else {
    //                     setErrOcc(true);
    //                     props.setLoading(false);
    //                 }
    //             })
    //             // Catch errors if any
    //             .catch((err) => {
    //                 console.warn(err);
    //                 setErrOcc(true);
    //                 props.setLoading(false);
    //             });
    //     }
    // }, []);

    useEffect(() => {
        const token = ReactSession.get("xTkn") ?? "";
        const isFree = ReactSession.get("ifr") ?? "unknown";
        if (isFree === "Free")
            props.setFreeUser(true)
        else
            props.setFreeUser(false)
        if (token !== "") {
            const endPoint = process.env.REACT_APP_AUTH_EP;
            axios({
                url: `${endPoint}dashboard/get_active_plans`,
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "ngrok-skip-browser-warning": "69420",
                }
            })
                .then((res) => {
                    if (res.data.success === true) {
                        setData(res.data.result);
                        setgRPCExpiry(res.data.result.filter(plan => plan.plan_name === "GRPC")[0]?.bill_details.cycle_end ?? "");
                    }
                    else {
                        setErrOcc(true);
                        props.setLoading(false);
                    }
                })
                // Catch errors if any
                .catch((err) => {
                    console.warn(err);
                    setErrOcc(true);
                    props.setLoading(false);
                });
        }
    }, []);

    useEffect(() => {
        if (data !== null)
            props.setLoading(false);
    }, [data]);

    const unshowCard = () => {
        setShowCancelCard(false);
        setPlanCancelled('');
        setPlanCancelName('');
    }

    return (
        <div>
            <Modal isOpen={showCancelCard} onClose={() => setShowCancelCard(false)} blockScrollOnMount={true}>
                <ModalOverlay />
                <ModalContent bg={"brand.black"} border={"1px solid"} borderColor={"brand.midGrey"} borderRadius={"xl"}>
                
                <ModalCloseButton />
                <ModalBody py={4}>
                    <Text
                        as="div"
                        fontSize={{ base: "md", md: "md" }}
                        fontWeight={"600"}
                        color={"brand.white"}
                        textAlign={"center"}
                        fontFamily={"heading"}
                    >
                        Are you sure you want to cancel <Text as="span" color={"brand.yellow"}>{planCancelName}</Text> plan?
                    </Text>
                    <Text
                        as="div"
                        fontSize={{ base: "xs", md: "xs" }}
                        fontWeight={"400"}
                        color={"brand.white"}
                        textAlign={"start"}
                        mt={4}
                        
                    >
                        Note: The plan will be valid till the end of the billing cycle, no further charges will be deducted.
                    
                    </Text>
                </ModalBody>
                    <ModalFooter>
                        {cancelStatus === "NO_ACTION" && <Flex width={"100%"} justifyContent={"center"}>
                            <Button colorScheme='blue' mr={3} onClick={() => cancelPlanNow(planCancelled)}
                                color={"brand.dark"}
                                bg={"brand.white"}
                                _hover={{
                                  bg: "brand.black",
                                  color: "brand.white",
                                }}
                                px={"20px"}
                                fontFamily={"heading"}
                                fontSize={"12px"}
                                lineHeight={"12px"}
                                size={"sm"}
                                borderRadius={"full"}    
                            >
                                Yes
                            </Button>
                            <Button colorScheme='blue' mr={3} onClick={() => unshowCard()}
                                color={"brand.dark"}
                                bg={"brand.white"}
                                _hover={{
                                  bg: "brand.black",
                                  color: "brand.white",
                                }}
                                px={"20px"}
                                fontFamily={"heading"}
                                fontSize={"12px"}
                                lineHeight={"12px"}
                                size={"sm"}
                                borderRadius={"full"}    
                            >
                                No
                            </Button>
                        </Flex>}
                        {cancelStatus === "CANCELLING" && <Flex w={"100%"} justifyContent={"center"} height={"24px"}>
                            <BeatLoader color="#fff" loading={true} size={10} />
                        </Flex>}
                        {cancelStatus === "CANCELLED" && <Flex w={"100%"} justifyContent={"center"} height={"24px"}>
                            <FaCheck color="#39e75f"/>
                        </Flex>}
                        {cancelStatus === "NOT_CANCELLED" && <Flex w={"100%"} justifyContent={"center"} height={"24px"}>
                            <ImCross color="#e3242b"/>
                        </Flex>}
                        
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {(!props.isLoading && (data !== null)) && 
                <Container maxW={"8xl"}>
                <Box
                    as="div"
                >
                    <Box>
                        <Text
                            as={'div'}
                            fontFamily={'heading'}
                            color={'brand.white'}
                            fontSize={'2xl'}
                            lineHeight={'3xl'}
                            fontWeight={'600'}
                        >
                            Billing Overview
                        </Text>
                    </Box>
                </Box>
                
                <Box>
                    {Array.isArray(data) && data.map((plan) => (
                        (plan.plan_name !== "FREE") ? (plan.plan_name === "GRPC") ?
                        <Stack direction={{ base: 'column', lg: 'row' }} mt={6} spacing={0.5}>
                            <Box
                                width={{ base: '100%', lg: '80%' }}
                                bg={'black'}
                                borderTopLeftRadius={'2xl'}
                                borderBottomLeftRadius={'2xl'}
                                borderTopRightRadius={{base: '2xl', lg: "0px"}}
                                borderBottomRightRadius={{base: '2xl', lg: "0px"}}
                                px={{base: 6, md: 8}}
                                pt={{base: 4, md: 3}}
                                pb={{base: 4, md: 4}}
                            >
                                <Flex justifyContent={'space-between'} alignItems={'center'} mt={4} fontSize={{ base: '14px', lg: 'md' }}>
                                    <Text as={'div'} color={'brand.white'}>Plan Name</Text>
                                    <Text as={'div'} color={'brand.yellow'} fontFamily={'heading'}>
                                        {plan.plan_name ?? "gRPC Service"}
                                    </Text>
                                </Flex>
                                <Flex justifyContent={'space-between'} alignItems={'center'} mt={{base: 0, lg: 4}} fontSize={{ base: '14px', lg: 'md' }}>
                                    <Text as={'div'} color={'brand.white'}>{(plan?.is_on_trial)?"Trial Period":"Billing Cycle"}</Text>
                                    <Text as={'div'} color={'brand.yellow'} fontFamily={'heading'}>
                                        {new Date(plan.bill_details.cycle_start).getDate()}{' '}
                                        {months[new Date(plan.bill_details.cycle_start).getUTCMonth()]}{' '}
                                        {new Date(plan.bill_details.cycle_start).getUTCFullYear()} -{' '}
                                        {new Date(plan.bill_details.cycle_end).getUTCDate()}{' '}
                                        {months[new Date(plan.bill_details.cycle_end).getUTCMonth()]}{' '}
                                        {new Date(plan.bill_details.cycle_end).getUTCFullYear()}
                                    </Text>
                                </Flex>
                                <Flex justifyContent={'space-between'} alignItems={'center'} mt={4} fontSize={{ base: '14px', lg: 'md' }}>
                                    <Text as={'div'} color={'brand.white'}>IPs Allowed</Text>
                                    <Text as={'div'} color={'brand.yellow'} fontFamily={'heading'}>
                                       {plan.bill_details.max_ips_allowed}
                                    </Text>
                                </Flex>
                                
                            </Box>
                            <Box
                                width={{ base: '100%', lg: '20%' }}
                                bg={'black'}
                                borderTopRightRadius={'2xl'}
                                borderBottomRightRadius={'2xl'}
                                borderTopLeftRadius={{base: '2xl', lg: "0px"}}
                                borderBottomLeftRadius={{base: '2xl', lg: "0px"}}
                                px={4}
                                py={{base: 4, lg: 6}}
                                
                            >
                                <Flex flexDirection={{sm: "row", lg: "column"}} justifyContent={'space-between'} alignItems={'center'} mt={{base: 0, lg: 1}}>
                                    <Box>
                                        <Text
                                            as={'div'}
                                            fontSize={{ base: '3xl', lg: '4xl' }}
                                            lineHeight={{ base: 1, lg: 1.1 }}
                                            color={'brand.red'}
                                            fontWeight={'600'}
                                            textAlign={{base: 'start', lg: 'center'}}
                                        >
                                            {plan.bill_details.currency === 'inr' ? '₹' : '$'}
                                            {plan.bill_details.amount_payable.usd ?? '--'}
                                        </Text>
                                        {(plan?.is_on_trial)?<Box>
                                            <Text as={'div'} color={'brand.white'} textAlign={'start'} fontSize={{ base: '11px', lg: '13px' }}>
                                                {(plan.cancel_at_cycle_end)?"Trial cancels at ":"Billing starts after"} {new Date(plan.bill_details.cycle_end).getUTCDate()}{' '}
                                                {months[new Date(plan.bill_details.cycle_end).getUTCMonth()]}{' '}
                                                {new Date(plan.bill_details.cycle_end).getUTCFullYear()}
                                            </Text>
                                        </Box>:
                                        <Text as={'div'} color={'brand.white'} textAlign={'center'} fontSize={{ base: '11px', lg: '13px' }}>
                                            {(plan.cancel_at_cycle_end)?"Expires ":"Auto renews "} on {new Date(plan.bill_details.cycle_end).getUTCDate()}{' '}
                                                {months[new Date(plan.bill_details.cycle_end).getUTCMonth()]}{' '}
                                                {new Date(plan.bill_details.cycle_end).getUTCFullYear()}
                                        </Text>}
                                        
                                        
                                    </Box>
                                    <Box>
                                        <Box mt={{base: "0px", lg: "14px"}} mb={{base: "0px", lg: "10px"}}  textAlign={'center'}>
                                            {!plan.cancel_at_cycle_end && <Button
                                                borderRadius={'full'}
                                                bg={'brand.red'}
                                                color="brand.white"
                                                _hover={{ bg: 'brand.white', color: 'brand.red' }}
                                                px={{base: "16px", lg: "20px"}}
                                                fontSize={{base: "10px", lg: "14px"}}
                                                height={{base: "24px", lg: "30px"}}
                                                mx={"auto"}
                                                onClick={() => {
                                                    setPlanCancelled(plan.plan_id);
                                                    setPlanCancelName(plan.plan_name);
                                                    setShowCancelCard(true);
                                                }} 
                                            >
                                                Cancel {(plan?.is_on_trial)?"trial":"Subscription"}
                                            </Button>} 
                                        </Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Stack>
                        : 
                        (plan.plan_name === "JUPITER")?"":<Stack direction={{ base: 'column', lg: 'row' }} mt={6} spacing={0.5}>
                            <Box
                                width={{ base: '100%', lg: '80%' }}
                                bg={'black'}
                                borderTopLeftRadius={'2xl'}
                                borderBottomLeftRadius={'2xl'}
                                borderTopRightRadius={{base: '2xl', lg: "0px"}}
                                borderBottomRightRadius={{base: '2xl', lg: "0px"}}
                                px={{base: 6, md: 8}}
                                pt={{base: 4, md: 3}}
                                pb={{base: 4, md: 4}}
                            >
                                <Flex justifyContent={'space-between'} alignItems={'center'} mt={4} fontSize={{ base: '14px', lg: 'md' }}>
                                    <Text as={'div'} color={'brand.white'}>Plan Name</Text>
                                    <Text as={'div'} color={'brand.yellow'} fontFamily={'heading'}>
                                        {plan.plan_name ?? "--"}
                                    </Text>
                                </Flex>
                                <Flex justifyContent={'space-between'} alignItems={'center'} mt={{base: 0, lg: 4}} fontSize={{ base: '14px', lg: 'md' }}>
                                    <Text as={'div'} color={'brand.white'}>Billing Cycle</Text>
                                    <Text as={'div'} color={'brand.yellow'} fontFamily={'heading'}>
                                    {new Date(plan.bill_details.cycle_start).getDate()}{' '}
                                        {months[new Date(plan.bill_details.cycle_start).getUTCMonth()]}{' '}
                                        {new Date(plan.bill_details.cycle_start).getUTCFullYear()} -{' '}
                                        {new Date(plan.bill_details.cycle_end).getUTCDate()}{' '}
                                        {months[new Date(plan.bill_details.cycle_end).getUTCMonth()]}{' '}
                                        {new Date(plan.bill_details.cycle_end).getUTCFullYear()}
                                    </Text>
                                </Flex>
                                <Flex justifyContent={'space-between'} alignItems={'center'} mt={4} fontSize={{ base: '14px', lg: 'md' }}>
                                    <Text as={'div'} color={'brand.white'}>Credits Used</Text>
                                    <Text as={'div'} color={'brand.yellow'} fontFamily={'heading'}>
                                        {plan.bill_details.credit_used ?? "--"} Credits
                                    </Text>
                                </Flex>
                                
                            </Box>
                            <Box
                                width={{ base: '100%', lg: '20%' }}
                                bg={'black'}
                                borderTopRightRadius={'2xl'}
                                borderBottomRightRadius={'2xl'}
                                borderTopLeftRadius={{base: '2xl', lg: "0px"}}
                                borderBottomLeftRadius={{base: '2xl', lg: "0px"}}
                                px={4}
                                py={{base: 4, lg: 8}}
                                
                            >
                                <Flex flexDirection={{sm: "row", lg: "column"}} justifyContent={'space-between'} alignItems={'center'} mt={{base: 0, lg: 0}}>
                                    <Box>
                                        <Text
                                            as={'div'}
                                            fontSize={{ base: '3xl', lg: '4xl' }}
                                            lineHeight={{ base: 1, lg: 1.1 }}
                                            color={'brand.red'}
                                            fontWeight={'600'}
                                            textAlign={{base: 'start', lg: 'center'}}
                                        >
                                            {plan.bill_details.currency === 'inr' ? '₹' : '$'}
                                            {plan.bill_details.amount_payable ?? '--'}
                                        </Text>
                                        <Text as={'div'} color={'brand.white'} textAlign={'center'} fontSize={{ base: '11px', lg: '13px' }}>
                                            {(plan.cancel_at_cycle_end)?"Expires ":"Auto renews "} on {new Date(plan.bill_details.cycle_end).getUTCDate()}{' '}
                                                {months[new Date(plan.bill_details.cycle_end).getUTCMonth()]}{' '}
                                                {new Date(plan.bill_details.cycle_end).getUTCFullYear()}
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Box mt={{base: "0px", lg: "14px"}} textAlign={'center'}>
                                            {!plan.cancel_at_cycle_end && <Button
                                                borderRadius={'full'}
                                                bg={'brand.red'}
                                                color="brand.white"
                                                _hover={{ bg: 'brand.white', color: 'brand.red' }}
                                                px={{base: "16px", lg: "20px"}}
                                                fontSize={{base: "10px", lg: "14px"}}
                                                height={{base: "24px", lg: "30px"}}
                                                mx={"auto"}
                                                onClick={() => {
                                                    setPlanCancelled(plan.plan_id);
                                                    setPlanCancelName(plan.plan_name);
                                                    setShowCancelCard(true);
                                                }} 
                                            >
                                                Cancel Subscription
                                            </Button>} 
                                        </Box>
                                    </Box>
                                </Flex>
                            </Box>
                        </Stack> : ""
                    ))}
                    
                </Box>
                {((Array.isArray(data) && data.length === 0) || (Array.isArray(data) && data.filter((plan) => plan.plan_name !== "FREE")?.length === 0))  && <Box>
                    <Box w={'full'} fontSize={{base: '14px', lg: 'md'}} bg={'black'} pt={4} pb={2} px={6} borderRadius={'lg'} mt={4}>
                        <Text as={'div'} color={'brand.white'} mb={2}>You are on <em>Free Plan</em>. No Active Subscriptions Found.</Text>
                        {/* <Text as={'div'} color={'brand.grey'}>Select a plan from <em>Pricing</em> to add card.</Text> */}
                    </Box>
                </Box>}
                {(data?.filter((plan) => plan.plan_name === "GRPC")[0]?.is_on_trial) && (gRPCExpiry!="") && <Text as={'div'} color={'brand.midGrey'} fontSize={{base: '14px', lg: '14px'}} pt={'5'} ps={'1'}>
                    gRPC billing starts from 
                    {' '}<Text as={"span"} color={"brand.yellow"}>{months[new Date(gRPCExpiry).getUTCMonth()]}{' '}{new Date(gRPCExpiry).getUTCDate()}{', '}
                                                {new Date(gRPCExpiry).getUTCFullYear()}</Text>. If the plan is cancelled during the trial period, no charges will be incurred.
                </Text>}
                {errorWhileCancelPlan && <Box px={4} bgColor={"#8B0000"} mt={6} borderRadius={'lg'}>
                    <Text as={'div'} color={'brand.white'} fontSize={{base: '14px', lg: '14px'}} py={'3'} ps={'1'} fontWeight={'600'}>
                        Some error occurred. To cancel your subscription, please reach out to us on <Text as={'a'} href="https://discord.gg/8JyZCjRPmr" target="_blank" rel="noopener noreferrer" color={'brand.yellow'} _hover={{ color: "brand.red" }}>Discord</Text> or <Text as={'a'} href="https://t.me/+elnozTFe3OY0NDA1" target="_blank" rel="noopener noreferrer" color={'brand.yellow'} _hover={{ color: "brand.red" }}>Telegram</Text>.
                    </Text>
                </Box>}
                
                <Box as="div" mt={8}>
                    <Box>
                        <Text
                            as={'div'}
                            fontFamily={'heading'}
                            color={'brand.white'}
                            fontSize={'2xl'}
                            lineHeight={'3xl'}
                            fontWeight={'600'}
                        >
                            Payment method
                        </Text>
                        <Text as={'div'} color={'brand.white'} fontSize={{base: '14px', lg: 'md'}} pt={'1'}>
                            Your last payment method details
                        </Text>
                        {(userCards.length > 0) && userCards.map((card) => <Box key={card.id} mt={6}>
                            <Box
                                bg={'black'}
                                borderRadius={'2xl'}
                                px={{ base: '16px', lg: '32px' }}
                                pt={{ base: '16px', lg: '24px' }}
                                pb={{ base: '36px', lg: '28px' }}
                                mt={4}
                            >
                                <Flex
                                    direction={{base: 'column', lg: "row"}}
                                    justifyContent={{ base: 'center', lg: 'space-between' }}
                                    alignItems={'center'}
                                >
                                    <Box w={'full'} maxW="400px">
                                        <Text as={'div'} color={'brand.white'}>{card.card.brand}</Text>
                                        <HStack color={'brand.white'} fontSize={{ base: 'xs', lg: 'sm' }} mt={2}>
                                            <Box w={'49%'} textAlign={'left'}>
                                                CARD NUMBER
                                            </Box>
                                            <Box w={'49%'} textAlign={'right'}>
                                                ***** {card.card.last4}
                                            </Box>
                                        </HStack>
                                        <HStack color={'brand.white'} fontSize={{ base: 'xs', lg: 'sm' }} mt={2}>
                                            <Box w={'49%'} textAlign={'left'}>
                                                CREDIT CARD
                                            </Box>
                                            <Box w={'49%'} textAlign={'right'}>
                                                {card.card.exp_month}/{card.card.exp_year}
                                            </Box>
                                        </HStack>
                                    </Box>
                                    <Box mt={{base: "0px", lg: "0px"}}>
                                        {/* <Button
                                            borderRadius={'full'}
                                            bg={'brand.red'}
                                            color="brand.white"
                                            _hover={{ bg: 'brand.white', color: 'brand.red' }}
                                            px={{base: "20px", lg: "32px"}}
                                            isLoading={(card.id === cardCancelled)}
                                            spinner={<BeatLoader size={8} color='white' />}
                                            onClick={() => removeCard(card.id)}
                                            fontSize={{base: "14px", lg: "16px"}}
                                        >
                                            Remove Card
                                        </Button> */}
                                        
                                    </Box>
                                </Flex>
                            </Box>
                        </Box>)}
                        {(userCards.length < 1) && <Box
                            bg={'black'}
                            borderRadius={'2xl'}
                            px={{ base: '22px', lg: '32px' }}
                            pt={{ base: '16px', lg: '24px' }}
                            pb={{ base: '16px', lg: '28px' }}
                            mt={6}
                        >
                            
                                <Box w={'full'} maxW="400px" fontSize={{base: '14px', lg: 'md'}}>
                                    <Text as={'div'} color={'brand.white'} mb={2}>No cards found.</Text>
                                    <Text as={'div'} color={'brand.grey'}>Select a plan from <em>Pricing</em> to add card.</Text>
                                </Box>
                                
                        </Box>}
                        <Text as={'div'} color={'brand.midGrey'} fontSize={{base: '14px', lg: '14px'}} pt={'5'} ps={'1'}>
                            To cancel your subscription, please reach out to us on <Text as={'a'} href="https://discord.gg/8JyZCjRPmr" target="_blank" rel="noopener noreferrer" color={'brand.yellow'} _hover={{ color: "brand.red" }}>Discord</Text> or <Text as={'a'} href="https://t.me/+elnozTFe3OY0NDA1" target="_blank" rel="noopener noreferrer" color={'brand.yellow'} _hover={{ color: "brand.red" }}>Telegram</Text>.
                        </Text>
                    </Box>
                </Box>
            </Container>}
        </div>
    );
}

export default Billings;
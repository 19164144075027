import { Container, Stack, Box, Flex, Heading, Text, Button, Image } from '@chakra-ui/react';
import { Link as ExtLink } from '@chakra-ui/react';
import CombinedHeading from '../../common/CombinedHeading';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';


import { PopupButton } from "react-calendly";

export default function PricingStatic() {
    const MotionBox = motion(Box);
    const MotionStack = motion(Stack);
    return (
        <Stack as="div" bg={'#111'} id="shyft-pricing">
            <Container maxW={'7xl'} pt={{ base: 10, md: 32 }} pb={{ base: 4, md: 4 }}>
                <CombinedHeading
                    topHeading="Simple and Transparent Plans"
                    mainHeading="Pricing That Makes Sense"
                    subHeading="Power your Solana projects with the best infrastructure layer."
                    isCenter={true}
                />
                <Flex as="div" pt={{ base: 6, md: 8 }} flexWrap={"wrap"}>
                    <MotionBox
                        border={'2px solid'}
                        borderColor="brand.lightGray"
                        px={"16px"}
                        py={"8px"}
                        me={"8px"}
                        borderRadius={"8px"}
                        mt={{base: "6px", md: "0px"}}
                    >
                        <Box as="div" color="brand.midGrey" fontSize={{ base: 'xs', md: 'md' }}> 1 API call =  100 credits</Box>
                    </MotionBox>
                    <MotionBox
                        border={'2px solid'}
                        borderColor="brand.lightGray"
                        px={"16px"}
                        py={"8px"}
                        borderRadius={"8px"}
                        me={"8px"}
                        mt={{base: "6px", md: "0px"}}
                    >
                        <Box as="div" color="brand.midGrey" fontSize={{ base: 'xs', md: 'md' }}> 1 RPC call =  1 credit</Box>
                    </MotionBox>
                    <MotionBox
                        border={'2px solid'}
                        borderColor="brand.lightGray"
                        px={"16px"}
                        py={"8px"}
                        borderRadius={"8px"}
                        me={"8px"}
                        mt={{base: "6px", md: "0px"}}
                    >
                        <Box as="div" color="brand.midGrey" fontSize={{ base: 'xs', md: 'md' }}> 1 callback =  1 credit</Box>
                    </MotionBox>
                </Flex>
                <Stack as="div" spacing={{ base: 5 }} pt={{ base: 6, md: 3 }} pb={{ base: 6, md: 8 }}>
                    <MotionBox
                        as="div"
                        border={'2px solid'}
                        borderColor="brand.lightGray"
                        borderRadius={{base: '2xl',md:'lg'}}
                        py={8}
                        px={8}
                        initial={{ opacity: 0, y: 10 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.3,delay: 0.2 }}
                    >
                        <Flex wrap={'wrap'} justifyContent={'space-between'}>
                            <Heading as="h3" color={'brand.white'} fontWeight={'600'} fontSize={'3xl'}>
                                Free{' '}
                                <Text as="span" fontSize={'sm'} fontWeight={'light'} fontFamily={'body'}>
                                    to get started
                                </Text>
                            </Heading>
                            <Link
                                to={"/get-api-key"}
                            >
                                <Box bg={'transparent'}
                                color={'brand.white'}
                                border={"1px solid"}
                                borderColor={"brand.red"}
                                w={{ base:"180px" }}
                                textAlign={"center"}
                                rounded={'full'}
                                py={{base: "8px"}}
                                px={{base: "32px"}}
                                // size={"sm"}
                                fontFamily={'heading'}
                                fontWeight={500}
                                fontSize={{base: 'xs', md: 'sm'}}
                                _hover={{
                                    textDecoration: "none",
                                    bg:"brand.red"
                                }}
                                
                                display={{base: "none", md: "initial"}}>
                                    Start for Free 
                                </Box>
                                
                                
                            </Link>
                        </Flex>
                        <Flex pt={{ base: 4, md: 8 }} pe={1} direction={{base: "column", md:"row"}} justifyContent={'space-between'} flexWrap={'wrap'}>
                            <Stack direction={'row'} py={1} me={{ base: '12px', lg: '0px' }}>
                                <Box>
                                    <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                </Box>
                                <Text
                                    fontFamily={'Jost'}
                                    fontSize={{ base: '14px', md: 'md' }}
                                    color="brand.midGrey"
                                    fontWeight={'500'}
                                >
                                    {' '}
                                    500K credits/month
                                </Text>
                            </Stack>
                            <Stack direction={'row'} py={1} me={{ base: '12px', lg: '0px' }}>
                                <Box>
                                    <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                </Box>
                                <Text
                                    fontFamily={'Jost'}
                                    fontSize={{ base: '14px', md: 'md' }}
                                    color="brand.midGrey"
                                    fontWeight={'500'}
                                >
                                    {' '}
                                    1 API/GraphQL req/sec
                                </Text>
                            </Stack>
                            <Stack direction={'row'} py={1} me={{ base: '12px', lg: '0px' }}>
                                <Box>
                                    <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                </Box>
                                <Text
                                    fontFamily={'Jost'}
                                    fontSize={{ base: '14px', md: 'md' }}
                                    color="brand.midGrey"
                                    fontWeight={'500'}
                                >
                                    {' '}
                                    30 RPC req/sec
                                </Text>
                            </Stack>
                            <Stack direction={'row'} py={1} me={{ base: '12px', lg: '0px' }}>
                                <Box>
                                    <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                </Box>
                                <Text
                                    fontFamily={'Jost'}
                                    fontSize={{ base: '14px', md: 'md' }}
                                    color="brand.midGrey"
                                    fontWeight={'500'}
                                >
                                    {' '}
                                    No Auto-Scaling (Capped at 500K)
                                </Text>
                            </Stack>
                            <Stack direction={'row'} py={1} me={{ base: '12px', lg: '0px' }}>
                                <Box>
                                    <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                </Box>
                                <Text
                                    fontFamily={'Jost'}
                                    fontSize={{ base: '14px', md: 'md' }}
                                    color="brand.midGrey"
                                    fontWeight={'500'}
                                >
                                    {' '}
                                    No Callbacks
                                </Text>
                            </Stack>
                        </Flex>
                        <Box w="2px" h={{base: "0px", md: "0px"}} mt={"-8px"}>

                        </Box>
                        <Link
                            to={"/get-api-key"}
                        >
                            <Box
                                bg={'transparent'}
                                color={'brand.white'}
                                border={"1px solid"}
                                borderColor={"brand.red"}
                                w={{ base:"160px" }}
                                textAlign={"center"}
                                rounded={'full'}
                                py={{base: "8px"}}
                                // size={"sm"}
                                fontFamily={'heading'}
                                fontWeight={500}
                                fontSize={{base: 'xs', md: 'sm'}}
                                mt={{base: "15px"}}
                                mx="auto"
                                display={{base: "block", md: "none"}}
                            >
                                Try SHYFT for Free
                            </Box>
                        </Link>
                    </MotionBox>
                    <Flex justifyContent={{ base: 'space-around', xl: 'space-between' }} flexWrap={'wrap'}>
                        <MotionStack
                            border={'2px solid'}
                            borderColor="brand.lightGray"
                            py={8}
                            px={10}
                            spacing={8}
                            width="360px"
                            mt={6}
                            className="each_plan"
                            initial={{ opacity: 0, y: 10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.3,delay: 0.3 }}
                        >
                            <Box>
                                <Image src="/assets/paper_plane.png" alt="plane" margin={'0 auto'} />
                            </Box>
                            <Text as={"div"} textAlign={'center'} fontSize={'18px'} color={'brand.white'}>
                                HACK
                            </Text>
                            <Flex justifyContent={'center'}>
                                <Box px={4}>
                                    <Text
                                        as={"div"} 
                                        fontSize={'4xl'}
                                        fontWeight={'700'}
                                        color={'brand.white'}
                                        fontFamily={'heading'}
                                    >
                                        $49
                                    </Text>
                                </Box>
                                <Box
                                    px={4}
                                    fontSize={'1xl'}
                                    fontWeight={'300'}
                                    color={'brand.white'}
                                    fontFamily={'heading'}
                                    py={0}
                                    borderLeft={'2px solid'}
                                    borderColor={'brand.white'}
                                    height={'50px'}
                                >
                                    <Text as={"div"}>PER</Text>
                                    <Text as={"div"}>MONTH</Text>
                                </Box>
                            </Flex>
                            <Stack spacing={4} className='plan_bullet_points'>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.yellow"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        10M credits every month
                                    </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        10 API/GraphQL req/sec
                                    </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        50 RPC req/sec
                                    </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        No Auto-Scaling (Capped at 5M)
                                    </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        Callbacks Enabled
                                    </Text>
                                </Stack>
                                {/* <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        Dedicated Discord/Telegram Channel
                                    </Text>
                                </Stack> */}
                                <Stack as="div" h={{base: "0px", md: "4px"}} w={{base: "0px", md: "20px"}}>

                                </Stack>
                            </Stack>
                            <Stack>
                                <ExtLink
                                    as={"a"}
                                    href={'/dashboard/pricing'}
                                    _hover={{
                                        textDecoration: "none"
                                    }}
                                >
                                    <Box
                                        size={'sm'}
                                        width={'40'}
                                        height={'10'}
                                        margin={'0 auto'}
                                        border={"1px solid"}
                                        borderColor={'brand.red'}
                                        borderRadius={'full'}
                                        className="each_plan_button"
                                        fontFamily={'heading'}
                                        fontWeight={"500"}
                                        fontSize={{base: "sm", md: "sm"}}
                                        lineHeight={"38px"}
                                        textAlign={"center"}
                                        _hover={{
                                            textDecoration: "none"
                                        }}
                                    >
                                        Get Started
                                    </Box>
                                </ExtLink>
                            </Stack>
                        </MotionStack>
                        <MotionStack
                            border={'2px solid'}
                            borderColor="brand.lightGray"
                            py={8}
                            px={8}
                            spacing={8}
                            width="360px"
                            mt={6}
                            className="each_plan"
                            initial={{ opacity: 0, y: 10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.3,delay: 0.4 }}
                        >
                            <Box>
                                <Image src="/assets/plane.png" alt="plane" margin={'0 auto'} />
                            </Box>
                            <Text as={"div"} textAlign={'center'} fontSize={'18px'} color={'brand.white'}>
                                LAUNCH
                            </Text>
                            <Flex justifyContent={'center'}>
                                <Box px={4} borderRight={'2px solid'} borderColor={'brand.white'}>
                                    <Text
                                        as={"div"}
                                        fontSize={'4xl'}
                                        fontWeight={'700'}
                                        color={'brand.white'}
                                        fontFamily={'heading'}
                                    >
                                        $199
                                    </Text>
                                </Box>
                                <Box
                                    px={4}
                                    fontSize={'1xl'}
                                    fontWeight={'300'}
                                    color={'brand.white'}
                                    fontFamily={'heading'}
                                >
                                    <Text as={"div"}>PER</Text>
                                    <Text as={"div"}>MONTH</Text>
                                </Box>
                            </Flex>
                            <Stack spacing={4}>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.yellow"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        100M credits every month
                                    </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        30 API/GraphQL req/sec
                                    </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        150 RPC req/sec
                                    </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" w={"24px"} />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        $3.3 for each additional 1M credits
                                    </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        Callbacks Enabled
                                    </Text>
                                </Stack>
                                {/* <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        Dedicated Discord/Telegram Channel
                                    </Text>
                                </Stack> */}
                            </Stack>
                            <Stack as="div" h={{base: "0px", md: "8px"}} w={{base: "0px", md: "20px"}}>

                            </Stack>
                            <Stack>
                                <ExtLink
                                    as={"a"}
                                    href={'/dashboard/pricing'}
                                    _hover={{
                                        textDecoration: "none"
                                    }}
                                >
                                    <Box
                                        size={'sm'}
                                        width={'40'}
                                        height={'10'}
                                        margin={'0 auto'}
                                        border={"1px solid"}
                                        borderColor={'brand.red'}
                                        borderRadius={'full'}
                                        className="each_plan_button"
                                        fontFamily={'heading'}
                                        fontWeight={"500"}
                                        fontSize={{base: "sm", md: "sm"}}
                                        lineHeight={"38px"}
                                        textAlign={"center"}
                                        _hover={{
                                            textDecoration: "none"
                                        }}
                                    >
                                        Get Started
                                    </Box>
                                </ExtLink>
                            </Stack>
                        </MotionStack>
                        <MotionStack
                            border={'2px solid'}
                            borderColor="brand.lightGray"
                            py={8}
                            px={8}
                            spacing={8}
                            width="360px"
                            mt={6}
                            className="each_plan"
                            initial={{ opacity: 0, y: 10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.3,delay: 0.4 }}
                        >
                            <Box>
                                <Image src="/assets/rocket.png" alt="plane" margin={'0 auto'} />
                            </Box>
                            <Text as={"div"} textAlign={'center'} fontSize={'18px'} color={'brand.white'}>
                                SCALE
                            </Text>
                            <Flex justifyContent={'center'}>
                                <Box px={4} borderRight={'2px solid'} borderColor={'brand.white'}>
                                    <Text
                                        as={"div"}
                                        fontSize={'4xl'}
                                        fontWeight={'700'}
                                        color={'brand.white'}
                                        fontFamily={'heading'}
                                    >
                                        $499
                                    </Text>
                                </Box>
                                <Box
                                    px={4}
                                    fontSize={'1xl'}
                                    fontWeight={'300'}
                                    color={'brand.white'}
                                    fontFamily={'heading'}
                                >
                                    <Text as={"div"}>PER</Text>
                                    <Text as={"div"}>MONTH</Text>
                                </Box>
                            </Flex>
                            <Stack spacing={4}>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.yellow"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        500M credits every month
                                    </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        150 API/GraphQL req/sec
                                    </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        400 RPC req/sec
                                    </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" w={"24px"} />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        $1.65 for each additional 1M credits
                                    </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        Callbacks Enabled
                                    </Text>
                                </Stack>
                                <Stack direction={'row'}>
                                    <Box>
                                        <Image src="/assets/Icons/vector-tick.svg" alt="tick" />
                                    </Box>
                                    <Text
                                        as={"div"}
                                        fontFamily={'Jost'}
                                        fontSize={{ base: '14px', md: 'md' }}
                                        color="brand.midGrey"
                                        fontWeight={'500'}
                                    >
                                        {' '}
                                        Dedicated Discord/Telegram Channel
                                    </Text>
                                </Stack>
                            </Stack>
                            <Stack>
                                <ExtLink
                                    as={"a"}
                                    href={'/dashboard/pricing'}
                                    _hover={{
                                        textDecoration: "none"
                                    }}
                                >
                                    <Box
                                        size={'sm'}
                                        width={'40'}
                                        height={'10'}
                                        margin={'0 auto'}
                                        border={"1px solid"}
                                        borderColor={'brand.red'}
                                        borderRadius={'full'}
                                        className="each_plan_button"
                                        fontFamily={'heading'}
                                        fontWeight={"500"}
                                        fontSize={{base: "sm", md: "sm"}}
                                        lineHeight={"38px"}
                                        textAlign={"center"}
                                        _hover={{
                                            textDecoration: "none"
                                        }}
                                    >
                                        Get Started
                                    </Box>
                                </ExtLink>
                            </Stack>
                        </MotionStack>
                    </Flex>
                </Stack>
                <Box>
                    <Flex as="div" pt={{ base: 2, md: 2 }} flexWrap={"wrap"} justifyContent={{sm: "space-around", md: "space-between"}}>
                        <MotionStack
                            initial={{ opacity: 0, y: 10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.3,delay: 0.1 }}
                            maxW={{base: "100%", md:"49%"}}
                        >
                            <Box
                                as="div"
                                border={'2px solid'}
                                borderColor="brand.lightGray"
                                borderRadius={'3xl'}
                                py={8}
                                px={8}
                                bg={"transparent"}
                                mt={6}
                            >
                                <Stack wrap={'wrap'} justifyContent={'space-between'}>
                                    <Heading as="h3" color={'brand.white'} fontWeight={'600'} fontSize={'3xl'}>
                                        Shared gRPC Network{' '}
                                        
                                    </Heading>
                                    
                                </Stack>
                                <Stack mt={2}>
                                    <Text color={"brand.grey"} fontSize={{ base: '14px', lg: 'md' }} fontWeight={500}>No credits, no metering, only unlimited streaming. Select the region closest to you and start instantly at <Text as={"span"} color={"brand.yellow"}>$199/mo/ip</Text>. Each region is powered by multiple nodes for fault tolerance. 
                                        {/* Reach out to us on <Text as={"a"} href="https://discord.gg/8JyZCjRPmr" target="_blank" color={"brand.yellow"} _hover={{ color: "brand.red" }}>discord</Text> for queries. */}
                                    </Text>
                                </Stack>
                                <Stack mt={{base: 0, md:6}}>
                                    {/* <PopupButton
                                        url="https://calendly.com/shyft-to"
                                        rootElement={document.getElementsByTagName("body")[0]}
                                        text=""
                                        className={"calendly_button_common"}
                                    /> */}
                                    <Button
                                        as={"a"}
                                        href='/dashboard/grpc'
                                        target="_blank"
                                        pt={"11px"}
                                        pb={"12px"}
                                        display={"block"}
                                        size={"sm"}
                                        // width={"28"}
                                        height={"10"}
                                        maxW={"170px"}
                                        border={"1px solid"}
                                        borderColor={'brand.red'}
                                        bgColor={'brand.black'}
                                        color={'brand.white'}
                                        borderRadius={"full"}
                                        textColor={"#fff"}
                                        fontFamily={"heading"}
                                        fontWeight={"600"}
                                        _hover={{
                                            bgColor: 'brand.red',
                                            color: 'brand.white',
                                            textDecoration: 'none',
                                        }}
                                        mt={{base: 3, md: 1, lg: 1}}
                                        textAlign={"center"}
                                    >
                                        Unlock gRPC
                                    </Button>
                                    {/* <ExtLink
                                        as={"a"}
                                        href={'/dashboard/pricing'}
                                        _hover={{
                                            textDecoration: "none"
                                        }}
                                    >
                                        <Box
                                            size={'sm'}
                                            width={'40'}
                                            height={'10'}
                                            // margin={'0 auto'}
                                            border={"1px solid"}
                                            borderColor={'brand.red'}
                                            borderRadius={'full'}
                                            className="each_plan_button"
                                            fontFamily={'heading'}
                                            fontWeight={"500"}
                                            fontSize={{base: "sm", md: "sm"}}
                                            lineHeight={"38px"}
                                            textAlign={"center"}
                                            _hover={{
                                                textDecoration: "none",
                                                bgColor: 'brand.red',
                                            }}
                                        >
                                            Get Started
                                        </Box>
                                    </ExtLink> */}
                                </Stack>
                            </Box>
                        </MotionStack>
                        <MotionStack
                            initial={{ opacity: 0, y: 10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.3,delay: 0.2 }}
                            maxW={{base: "100%", md:"49%"}}
                            id="jup-swap-v6-api"
                        >
                            <Box
                                as="div"
                                border={'2px solid'}
                                borderColor="brand.lightGray"
                                borderRadius={'3xl'}
                                py={8}
                                px={8}
                                bg={"transparent"}
                                mt={6}
                                
                            >
                                <Stack wrap={'wrap'} justifyContent={'space-between'}>
                                    <Heading as="h3" color={'brand.white'} fontWeight={'600'} fontSize={'3xl'}>
                                        JUP Swap API 🔥{' '}
                                        
                                    </Heading>
                                    
                                </Stack>
                                <Stack mt={2}>
                                    <Text color={"brand.grey"} fontSize={{ base: '14px', lg: 'md' }} fontWeight={500}>Access region specific JUP Swap API. No self-hosting required, start trading instantly at <Text as={"span"} color={"brand.yellow"}>$299/mo</Text> and <Text as={"span"} color={"brand.yellow"}>150 req/sec</Text>. No credit system or upper monthly limit. Reach out to us on <Text as={"a"} href="https://discord.gg/8JyZCjRPmr" target="_blank" color={"brand.yellow"} _hover={{ color: "brand.red" }}>discord</Text> to get access.</Text>
                                </Stack>
                                <Stack mt={{base: 0, md:6}}>
                                <Button
                                    as={"a"}
                                    href="https://discord.gg/8JyZCjRPmr"
                                    target="_blank"
                                    pt={"12px"}
                                    pb={"10px"}
                                    display={"block"}
                                    size={"sm"}
                                    // width={"28"}
                                    height={"10"}
                                    maxW={"170px"}
                                    border={"1px solid"}
                                    borderColor={'brand.red'}
                                    bgColor={'brand.black'}
                                    color={'brand.white'}
                                    borderRadius={"full"}
                                    textColor={"#fff"}
                                    fontFamily={"heading"}
                                    fontWeight={"500"}
                                    _hover={{
                                        bgColor: 'brand.red',
                                        color: 'brand.white',
                                        textDecoration: 'none',
                                    }}
                                    mt={{base: 3, md: 1, lg: 0}}
                                    textAlign={"center"}
                                >
                                    Connect with us
                                </Button>
                                </Stack>
                            </Box>
                        </MotionStack>
                    </Flex>
                </Box>
                <Box>
                    <Flex as="div" pt={{ base: 2, md: 2 }} flexWrap={"wrap"} justifyContent={{sm: "space-around", md: "space-between"}}>
                    <MotionStack
                            initial={{ opacity: 0, y: 10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.3,delay: 0.3 }}
                            maxW={{base: "100%", md:"49%"}}
                        >
                            <Box
                                as="div"
                                border={'2px solid'}
                                borderColor="brand.lightGray"
                                borderRadius={'3xl'}
                                py={8}
                                px={8}
                                bg={"transparent"}
                                mt={6}
                            >
                                <Stack wrap={'wrap'} justifyContent={'space-between'}>
                                    <Heading as="h3" color={'brand.white'} fontWeight={'600'} fontSize={'3xl'}>
                                        Enterprise{' '}
                                        
                                    </Heading>
                                    
                                </Stack>
                                <Stack mt={2}>
                                    <Text color={"brand.grey"} fontSize={{ base: '14px', lg: 'md' }} fontWeight={500}>For business with larger volume or unique business models. We provide custom plans based on your credit and rate limit requirements.
                                        <br />Reach out to us on <Text as={"a"} href="https://discord.gg/8JyZCjRPmr" target="_blank" color={"brand.yellow"} _hover={{ color: "brand.red" }}>discord</Text> for queries.
                                    </Text>
                                </Stack>
                                <Stack mt={{base: 3, md:6}}>
                                    <PopupButton
                                        url="https://calendly.com/shyft-to"
                                        rootElement={document.getElementsByTagName("body")[0]}
                                        text=""
                                        className={"calendly_button_common"}
                                    />
                                    {/* <Button
                                        as={"a"}
                                        href="https://discord.gg/8JyZCjRPmr"
                                        target="_blank"
                                        pt={"11px"}
                                        pb={"12px"}
                                        display={"block"}
                                        size={"sm"}
                                        // width={"28"}
                                        height={"10"}
                                        maxW={"170px"}
                                        border={"1px solid"}
                                        borderColor={'brand.red'}
                                        bgColor={'brand.black'}
                                        color={'brand.white'}
                                        borderRadius={"full"}
                                        textColor={"#fff"}
                                        fontFamily={"heading"}
                                        fontWeight={"600"}
                                        _hover={{
                                            bgColor: 'brand.red',
                                            color: 'brand.white',
                                            textDecoration: 'none',
                                        }}
                                        mt={{base: 5, md: 1, lg: 1}}
                                        textAlign={"center"}
                                    >
                                        Connect with us
                                    </Button> */}
                                </Stack>
                            </Box>
                        </MotionStack>
                        <MotionStack
                            initial={{ opacity: 0, y: 10 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.3,delay: 0.4 }}
                            maxW={{base: "100%", md:"49%"}}
                        >
                            <Box
                                as="div"
                                border={'2px solid'}
                                borderColor="brand.lightGray"
                                borderRadius={'3xl'}
                                py={8}
                                px={8}
                                bg={"transparent"}
                                mt={6}
                                
                            >
                                <Stack wrap={'wrap'} justifyContent={'space-between'}>
                                    <Heading as="h3" color={'brand.white'} fontWeight={'600'} fontSize={'3xl'}>
                                        Dedicated Solana RPCs{' '}
                                        
                                    </Heading>
                                    
                                </Stack>
                                <Stack mt={2}>
                                    <Text color={"brand.grey"} fontSize={{ base: '14px', lg: 'md' }} fontWeight={500}>Boost your development with our fault-tolerant dedicated nodes. Starting from <Text as={"span"} color={"brand.yellow"}>$1800/mo</Text>. We also deploy a proxy which tracks your node's health and routes to our backup pool in case of lag or downtimes.</Text>
                                </Stack>
                                <Stack mt={{base: 0, md:6}}>
                                    <Button
                                        as={"a"}
                                        href="https://discord.gg/8JyZCjRPmr"
                                        target="_blank"
                                        pt={"12px"}
                                        pb={"10px"}
                                        display={"block"}
                                        size={"sm"}
                                        // width={"28"}
                                        height={"10"}
                                        maxW={"170px"}
                                        border={"1px solid"}
                                        borderColor={'brand.red'}
                                        bgColor={'brand.black'}
                                        color={'brand.white'}
                                        borderRadius={"full"}
                                        textColor={"#fff"}
                                        fontFamily={"heading"}
                                        fontWeight={"500"}
                                        _hover={{
                                            bgColor: 'brand.red',
                                            color: 'brand.white',
                                            textDecoration: 'none',
                                        }}
                                        mt={{base: 5, md: 1, lg: 0}}
                                        textAlign={"center"}
                                    >
                                        Connect with us
                                    </Button>
                                </Stack>
                            </Box>
                        </MotionStack>
                    </Flex>
                </Box>
                
            </Container>
        </Stack>
    );
}

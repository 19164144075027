import {
  Stack,
  Container,
  Flex,
  Box,
  Link,
  Text,
  Heading,
  ListItem,
  UnorderedList,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
  Badge,
} from "@chakra-ui/react";
import CombinedHeading from "../../common/CombinedHeading";
// import { motion } from "framer-motion";

export default function SampleProjects() {
//   const MotionStack = motion(Stack);
  const devGuides = [
    {
      id: 1,
      blogName:
        "How to build an eCommerce loyalty reward system with utility tokens on Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-build-an-ecommerce-loyalty-reward-system-with-utility-tokens-on-solana-part-1-28a398b71ec2?source=shyft-website",
      isNew: false,
    },
    // {
    //   id: 2,
    //   blogName: "Build your first Solana NFT marketplace",
    //   blogLink:
    //     "https://blogs.shyft.to/build-your-first-nft-marketplace-2e52f24ae7ec?source=shyft-website",
    //   isNew: false,
    // },
    {
      id: 3,
      blogName: "Building NFT based membership systems on Solana",
      blogLink:
        "https://blogs.shyft.to/part-1-membership-service-using-nfts-on-solana-c8ea0459ff52?source=shyft-website",
      isNew: false,
    },
    {
      id: 4,
      blogName: "How to create a Web3 game with utility tokens on Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-create-a-web3-game-with-utility-tokens-on-solana-part-1-2c8308203662?source=shyft-website",
      isNew: false,
    },
    {
      id: 5,
      blogName: "How to create a Token airdrop campaign on Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-create-a-token-airdrop-campaign-on-solana-part-1-577f8c5aba14?source=shyft-website",
      isNew: false,
    },
    // {
    //   id: 6,
    //   blogName: "How to create an NFT launchpad on Solana",
    //   blogLink:
    //     "https://blogs.shyft.to/how-to-create-an-nft-launchpad-on-solana-part-1-cccc017c4bf?source=shyft-website",
    //   isNew: true,
    // },
    {
      id: 7,
      blogName: "How to create a live activity feed on Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-create-an-nft-launchpad-on-solana-part-1-cccc017c4bf?source=shyft-website",
      isNew: false,
    },
  ];
  const NftBlogs = [
    {
      id: 1,
      blogName: "How to mint an NFT using it's metadata URI on Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-mint-an-nft-using-its-metadata-uri-on-solana-ed938833ceeb?source=shyft-website",
      isNew: false,
    },
    {
      id: 2,
      blogName: "How to get all NFTs from a Solana Wallet",
      blogLink:
        "https://blogs.shyft.to/read-all-nfts-from-a-wallet-444025c9d7ed?source=shyft-website",
      isNew: false,
    },
    {
      id: 3,
      blogName: "Using NFTs as access tokens in Solana",
      blogLink:
        "https://blogs.shyft.to/build-nft-gated-dapp-397ee39dc033?source=shyft-website",
      isNew: false,
    },
    {
      id: 4,
      blogName: "Mint and airdrop Solana NFTs",
      blogLink:
        "https://blogs.shyft.to/mint-and-reward-users-with-edition-nfts-e7d655a20d44?source=shyft-website",
      isNew: false,
    },
    {
      id: 5,
      blogName: "How to get NFT owners on Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-get-nft-owners-on-solana-c90992b15007?source=shyft-website",
      isNew: false,
    },
    {
      id: 6,
      blogName: "Exploring the ultimate NFT Reading Solution on Solana",
      blogLink:
        "https://blogs.shyft.to/exploring-the-ultimate-nft-reading-solution-on-solana-72be3531d3b0?source=shyft-website",
      isNew: false,
    },
    {
      id: 7,
      blogName: "How to update metadata URI for an NFT on Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-update-metadata-uri-for-an-nft-on-solana-4e058edfead9?source=shyft-website",
      isNew: false,
    },
    {
      id: 8,
      blogName: "Build your first Solana NFT dApp",
      blogLink:
        "https://blogs.shyft.to/build-your-first-nft-dapp-cd499445ffa6?source=shyft-website",
      isNew: false,
    },
  ];
  const cNFTBlogs = [
    {
      id: 1,
      blogName: "How to mint a Compressed NFT on Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-mint-a-compressed-nft-on-solana-6e7c0098e51e?source=shyft-website",
      isNew: false,
    },
    {
      id: 2,
      blogName: "Working with compressed NFTs on Solana",
      blogLink:
        "https://blogs.shyft.to/working-with-compressed-nfts-on-solana-afb4bcf20bc2?source=shyft-website",
      isNew: true,
    },
    {
      id: 3,
      blogName: "Building a compressed collection NFT on Solana",
      blogLink:
        "https://blogs.shyft.to/build-a-collection-compressed-nft-with-shyft-api-d901f77d3581?source=shyft-website",
      isNew: true,
    },
  ];
  const tokenBlogs = [
    {
      id: 1,
      blogName: "How to get token balances in Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-get-token-balances-5b041c859d44?source=shyft-website",
      isNew: false,
    },
    {
      id: 2,
      blogName: "How to airdrop SPL-20 tokens to hundreds of wallets on Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-airdrop-spl-20-tokens-to-hundreds-of-wallets-on-solana-6aa88253a5e5?source=shyft-website",
      isNew: false,
    },
    {
      id: 3,
      blogName: "How to create, mint and airdrop a custom SPL token on Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-create-mint-and-airdrop-a-custom-spl-token-on-solana-7f3d3dd5f44?source=shyft-website",
      isNew: false,
    },
  ];
  const TxnBlogs = [
    {
      id: 1,
      blogName: "How to get decoded Solana Transactions",
      blogLink:
        "https://blogs.shyft.to/how-to-get-decoded-solana-transactions-d73d57ef5b66?source=shyft-website",
      isNew: false,
    },
    {
      id: 2,
      blogName: "How to create a Web3 marketplace activity feed",
      blogLink:
        "https://blogs.shyft.to/how-to-create-a-web3-marketplace-activity-feed-ff7f14d1519c?source=shyft-website",
      isNew: false,
    },
    {
      id: 3,
      blogName: "Building a Discord Bot with Shyft APIs",
      blogLink:
        "https://blogs.shyft.to/building-a-discord-bot-with-shyft-api-stay-updated-on-compressed-nfts-7e9c48a1b15e?source=shyft-website",
      isNew: false,
    },
    {
      id: 4,
      blogName: "How to parse raw transactions in Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-parse-raw-transaction-in-solana-ed392e95e5dd?source=shyft-website",
      isNew: false,
    },
    {
      id: 5,
      blogName: "Track cNFT marketplace events in real-time",
      blogLink:
        "https://blogs.shyft.to/track-cnft-marketplace-events-in-real-time-8626e537f77d?source=shyft-website",
      isNew: false,
    },
  ];
  const solanaBrainfood = [
    {
      id: 4,
      blogName: "Tracking NFTs sold on Tensor over a period of time",
      blogLink:
        "https://blogs.shyft.to/tracking-sold-nfts-on-tensor-3f12670430a3?source=shyft-website",
      isNew: true,
    },
    {
      id: 3,
      blogName: "Tracking loan events on Famous Fox Citrus",
      blogLink:
        "https://blogs.shyft.to/tracking-loan-events-on-famous-fox-citrus-f294ba29fdf5?source=shyft-website",
      isNew: true,
    },
    {
      id: 2,
      blogName: "How to track votes for realms proposal on Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-track-votes-for-realms-proposal-on-solana-807210b6e6c6?source=shyft-website",
      isNew: true,
    },
    {
      id: 1,
      blogName:
        "How to track ticket purchased for a Famous fox raffle on Solana",
      blogLink:
        "https://blogs.shyft.to/how-to-track-ticket-purchased-for-a-famous-fox-raffle-on-solana-9db91a36acd?source=shyft-website",
      isNew: false,
    },
  ];
  const defiBlogs = [
    {
      id: 1,
      blogName: "How to fetch the liquidity details of a Token on Solana",
      blogLink: "https://blogs.shyft.to/how-to-fetch-the-liquidity-details-of-a-token-on-solana-3b92b7e2d93f?source=shyft=website",
      isNew: false,
    },
  ];

  const grpcBlogs = [
    {
      id: 1,
      blogName: "How to stream real-time Solana Transactions using Shyft's gRPC Service",
      blogLink: "https://blogs.shyft.to/how-to-stream-real-time-solana-transactions-using-shyfts-grpc-service-1a8f8ad44da3?shyft-website",
      isNew: false,
    },
    {
      id: 2,
      blogName: "How to track new pools on Raydium with Shyft gRPC",
      blogLink: "https://blogs.shyft.to/how-to-track-new-pools-on-raydium-with-shyft-grpc-2497df832ea0?source=shyft-website",
      isNew: false,
    },
    {
      id: 3,
      blogName: "Launching liquidity pools on Raydium with safeguarding strategy to counter bot manipulation",
      blogLink: "https://blogs.shyft.to/launching-liquidity-pool-on-raydium-and-safeguarding-strategies-to-counter-bot-manipulation-part-1-a675f782382c?source=shyft-website",
      isNew: false,
    },
    {
      id: 4,
      blogName: "How to track new pools on Raydium with Shyft gRPC",
      blogLink: "https://blogs.shyft.to/how-to-build-a-bot-for-listening-to-new-raydium-pools-with-shyft-grpcs-600e1034277e?source=shyft-website",
      isNew: false,
    },
    {
      id: 4,
      blogName: "Real-time Solana Data Streaming with gRPC: Account, Transactions, and Blocks",
      blogLink: "https://blogs.shyft.to/real-time-data-streaming-with-grpc-accounts-transactions-blocks-734733e491ea?source=shyft-website",
      isNew: false,
    },
    {
      id: 5,
      blogName: "How to stream real-time Pump.fun updates on Solana",
      blogLink: "https://blogs.shyft.to/how-to-stream-real-time-pump-fun-updates-on-solana-29a46922ac5d?source=shyft-website",
      isNew: false,
    },
    {
      id: 6,
      blogName: "How to stream Solana Moonshot transactions in real-time with gRPC",
      blogLink: "https://blogs.shyft.to/how-to-stream-solana-moonshot-transactions-in-real-time-with-grpc-9b514099e1e4?source=shyft-website",
      isNew: false,
    },
    {
      id: 7,
      blogName: "How to stream Bonding Curve Transactions on Pump.fun",
      blogLink: "https://blogs.shyft.to/how-to-stream-bonding-curve-transactions-on-pumpfun-b422f5958c7f?source=shyft-website",
      isNew: false,
    },
    {
      id: 8,
      blogName: "How to stream new token launches on Pump.fun in real-time",
      blogLink: "https://blogs.shyft.to/how-to-stream-new-token-launches-on-pump-fun-in-real-time-188a6f9fa3fb?source=shyft-website",
      isNew: false,
    },
    {
      id: 9,
      blogName: "How to track token transfers from Pump.fun to Raydium",
      blogLink: "https://blogs.shyft.to/how-to-track-token-transfers-from-pump-fun-to-raydium-5ada83c2ac58?source=shyft-website",
      isNew: false,
    },
  ]

  const replits = {
    defaultReps: [
      {
        id: 1,
        blogName: "How to get parsed transactions from jupiter v6",
        blogLink: "https://replit.com/@shyft-to/get-parsed-transaction-of-jupiter-v6-from-grpc?v=1",
        isNew: false,
      },
      {
        id: 2,
        blogName: "How to get liquidity details of a token",
        blogLink: "https://replit.com/@shyft-to/gRpc-defiLiquidityDetails?v=1",
        isNew: true,
      },
      {
        id: 3,
        blogName: "How to create a Solana Arbitrage Bot",
        blogLink: "https://replit.com/@shyft-to/grpcarbitrage?v=1",
        isNew: true,
      },
      {
        id: 4,
        blogName: "How to get parsed meteora transactions",
        blogLink: "https://replit.com/@shyft-to/get-parsed-transaction-of-meteora-from-grpc?v=1",
        isNew: false,
      }
    ],
    raydiumRepls: [
      {
        id: 1,
        blogName: "How to get parsed Raydium amm instructions from gRPC",
        blogLink: "https://replit.com/@shyft-to/get-parsed-instructions-of-raydium-amm-from-grpc?v=1",
        isNew: false,
      },
      {
        id: 2,
        blogName: "How to track newly created pools on Raydium using gRPC",
        blogLink: "https://replit.com/@shyft-to/track-new-liquidity-pools-created-on-raydium-dex-using-grpc?v=1",
        isNew: true,
      },
      // {
      //   id: 3,
      //   blogName: "How to track Pump.fun token migrations to RAYDIUM",
      //   blogLink: "https://replit.com/@shyft-to/grpc-monitor-pumpfun-token-migration-raydium?v=1",
      //   isNew: false,
      // },
      {
        id: 4,
        blogName: "How to get live token prices on Raydium",
        blogLink: "https://replit.com/@shyft-to/RaydiumPrice?v=1",
        isNew: false,
      },
    ],
    pumpfunRepls: [
      {
        id: 1,
        blogName: "How to get parsed Pump.fun transactions using gRPC",
        blogLink: "https://replit.com/@shyft-to/get-parsed-transaction-of-pump-fun-from-grpc?v=1",
        isNew: false,
      },
      {
        id: 2,
        blogName: "How to get monitor Pump.fun token migrations in real-time",
        blogLink: "https://replit.com/@shyft-to/grpc-monitor-pumpfun-token-migration-raydium?v=1",
        isNew: false,
      },
      {
        id: 3,
        blogName: "How to get check Pump.fun token migration progress",
        blogLink: "https://replit.com/@shyft-to/grpc-Pumpfun-Progress?v=1",
        isNew: true,
      },
      {
        id: 4,
        blogName: "How to get real-time Pump.fun token prices",
        blogLink: "https://replit.com/@shyft-to/PumpfunPrice?v=1",
        isNew: false,
      },
    ],
    orcaRepls: [
      {
        id: 1,
        blogName: "How to get parsed accounts from Orca using gRPC",
        blogLink: "https://replit.com/@shyft-to/get-parsed-accounts-of-orca-from-grpc?v=1",
        isNew: true,
      },
      {
        id: 2,
        blogName: "How to get parsed Orca transactions using Shyft gRPC",
        blogLink: "https://replit.com/@shyft-to/get-parsed-transaction-of-orca-from-grpc?v=1",
        isNew: false,
      },

      {
        id: 3,
        blogName: "How to get parsed add liquidity transactions for Orca",
        blogLink: "https://replit.com/@shyft-to/grpc-orca-txn-liquidityadded?v=1",
        isNew: false,
      },
      {
        id: 4,
        blogName: "How to get real-time prices for Orca Dex",
        blogLink: "https://replit.com/@shyft-to/OrcaPrice?v=1",
        isNew: false,
      },
    ],
  }

  return (
    <Stack as="div" bg={"#111"}>
      <Container
        id="learn-solana-development-with-shyft"
        maxW={"7xl"}
        pt={{ base: 12, md: 16 }}
        pb={{ base: 4, md: 19 }}
      >
        <Stack spacing={12}>
          <CombinedHeading
            topHeading="Dev Content + Code Samples"
            mainHeading="Learn With Us"
            isCenter={true}
          />
          <Flex
            w={"full"}
            flexDirection={{ base: "column", md: "row" }}
            justifyContent={"space-between"}
            flexWrap={{ base: "wrap" }}
            pb={{ base: 12, md: "72px" }}
            display={{ base: "none", md: "flex" }}
          >
            <Box w={{ base: "full", md: "30%" }}>
              <Stack mt={{ base: "8px", md: "12px" }} pb={"8px"}>
                <Box
                  bgColor={"brand.faintGrey"}
                  borderRadius={"4px"}
                  py={{ base: "6px" }}
                  textAlign={"center"}
                >
                  <Heading
                    fontSize={{ base: "md", md: "lg" }}
                    fontWeight={"200"}
                    fontFamily={"heading"}
                    color={"brand.white"}
                    mb={0}
                  >
                    Transactions & Callbacks
                  </Heading>
                </Box>
                <Box py={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-3"}
                  >
                    {TxnBlogs.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </Stack>
              <Stack mt={{ base: "8px", md: "12px" }} pb={"8px"}>
                <Box
                  bgColor={"brand.faintGrey"}
                  borderRadius={"4px"}
                  py={{ base: "6px" }}
                  textAlign={"center"}
                >
                  <Heading
                    fontSize={{ base: "md", md: "lg" }}
                    fontWeight={"200"}
                    fontFamily={"heading"}
                    color={"brand.white"}
                    mb={0}
                  >
                    Solana Brainfood
                  </Heading>
                </Box>
                <Box py={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-3"}
                  >
                    {solanaBrainfood.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </Stack>
              <Stack mt={{ base: "8px", md: "12px" }} pb={"8px"}>
                <Box
                  bgColor={"brand.faintGrey"}
                  borderRadius={"4px"}
                  py={{ base: "6px" }}
                  textAlign={"center"}
                >
                  <Heading
                    fontSize={{ base: "md", md: "lg" }}
                    fontWeight={"200"}
                    fontFamily={"heading"}
                    color={"brand.white"}
                    mb={0}
                  >
                    Raydium Repl
                  </Heading>
                </Box>
                <Box py={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-3"}
                  >
                    {replits.raydiumRepls.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text
                            as={"div"}
                            lineHeight={"19px"}
                            fontSize={"16px"}
                          >
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}&nbsp;<ReplBadge />
                          </Text>           
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </Stack>
              
            </Box>
            <Box w={{ base: "full", md: "30%" }}>
              {/* <Stack mt={{ base: "8px", md: "12px" }}>
                <Box
                  bgColor={"brand.faintGrey"}
                  borderRadius={"4px"}
                  py={{ base: "6px" }}
                  textAlign={"center"}
                >
                  <Heading
                    fontSize={{ base: "md", md: "lg" }}
                    fontWeight={"200"}
                    fontFamily={"heading"}
                    color={"brand.white"}
                    mb={0}
                  >
                    NFTs
                  </Heading>
                </Box>
                <Box py={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-3"}
                  >
                    {NftBlogs.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </Stack> */}
              <Stack mt={{ base: "8px", md: "12px" }} pb={"8px"}>
                <Box
                  bgColor={"brand.faintGrey"}
                  borderRadius={"4px"}
                  py={{ base: "6px" }}
                  textAlign={"center"}
                >
                  <Heading
                    fontSize={{ base: "md", md: "lg" }}
                    fontWeight={"200"}
                    fontFamily={"heading"}
                    color={"brand.white"}
                    mb={0}
                  >
                    gRPC & Streaming
                  </Heading>
                </Box>
                <Box py={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"5"}
                    ms={"-3"}
                  >
                    {grpcBlogs.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </Stack>
              <Stack mt={{ base: "8px", md: "12px" }} pb={"8px"}>
                <Box
                  bgColor={"brand.faintGrey"}
                  borderRadius={"4px"}
                  py={{ base: "6px" }}
                  textAlign={"center"}
                >
                  <Heading
                    fontSize={{ base: "md", md: "lg" }}
                    fontWeight={"200"}
                    fontFamily={"heading"}
                    color={"brand.white"}
                    mb={0}
                  >
                    DeFi APIs & More
                  </Heading>
                </Box>
                <Box py={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"5"}
                    ms={"-3"}
                  >
                    {defiBlogs.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </Stack>
            </Box>
            <Box w={{ base: "full", md: "30%" }}>
              <Stack mt={{ base: "8px", md: "12px" }} pb={"8px"}>
                <Box
                  bgColor={"brand.faintGrey"}
                  borderRadius={"4px"}
                  py={{ base: "6px" }}
                  textAlign={"center"}
                >
                  <Heading
                    fontSize={{ base: "md", md: "lg" }}
                    fontWeight={"200"}
                    fontFamily={"heading"}
                    color={"brand.white"}
                    mb={0}
                  >
                    Pump.fun Repls
                  </Heading>
                </Box>
                
                <Box pt={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-3"}
                  >
                    {replits.pumpfunRepls.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}&nbsp;<ReplBadge />
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </Stack>
              <Stack mt={{ base: "8px", md: "12px" }} pb={"8px"}>
                <Box
                  bgColor={"brand.faintGrey"}
                  borderRadius={"4px"}
                  py={{ base: "6px" }}
                  textAlign={"center"}
                >
                  <Heading
                    fontSize={{ base: "md", md: "lg" }}
                    fontWeight={"200"}
                    fontFamily={"heading"}
                    color={"brand.white"}
                    mb={0}
                  >
                    Orca Repls
                  </Heading>
                </Box>
                <Box pt={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-3"}
                  >
                    {replits.orcaRepls.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}&nbsp;<ReplBadge />
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </Stack>
              <Stack mt={{ base: "8px", md: "12px" }} pb={"8px"}>
                <Box
                  bgColor={"brand.faintGrey"}
                  borderRadius={"4px"}
                  py={{ base: "6px" }}
                  textAlign={"center"}
                >
                  <Heading
                    fontSize={{ base: "md", md: "lg" }}
                    fontWeight={"200"}
                    fontFamily={"heading"}
                    color={"brand.white"}
                    mb={0}
                  >
                    Misc Repls
                  </Heading>
                </Box>
                
                <Box pt={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-3"}
                  >
                    {replits.defaultReps.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}&nbsp;<ReplBadge />
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </Stack>
            </Box>
          </Flex>
          <Accordion
            allowToggle
            display={{ base: "initial", md: "none" }}
            mb={12}
          >
            <AccordionItem border={"none"} pt={2}>
              <h2>
                <AccordionButton
                  color={"brand.white"}
                  bg={"brand.lightGrey"}
                  borderRadius={"8px"}
                  _expanded={{ bg: "brand.red" }}
                >
                  <Box as="span" flex="1" textAlign="left">
                    Transaction & Callbacks
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box py={1}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-6"}
                  >
                    {TxnBlogs.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem border={"none"} pt={2}>
              <h2>
                <AccordionButton
                  color={"brand.white"}
                  bg={"brand.lightGrey"}
                  borderRadius={"8px"}
                  _expanded={{ bg: "brand.red" }}
                >
                  <Box as="span" flex="1" textAlign="left">
                    Solana Brainfood
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box py={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-6"}
                  >
                    {solanaBrainfood.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem border={"none"} pt={2}>
              <h2>
                <AccordionButton
                  color={"brand.white"}
                  bg={"brand.lightGrey"}
                  borderRadius={"8px"}
                  _expanded={{ bg: "brand.red" }}
                >
                  <Box as="span" flex="1" textAlign="left">
                    gRPC & Streaming
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box py={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-6"}
                  >
                    {grpcBlogs.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem border={"none"} pt={2}>
              <h2>
                <AccordionButton
                  color={"brand.white"}
                  bg={"brand.lightGrey"}
                  borderRadius={"8px"}
                  _expanded={{ bg: "brand.red" }}
                >
                  <Box as="span" flex="1" textAlign="left">
                    DeFi APIs & More
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box py={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-6"}
                  >
                    {defiBlogs.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem border={"none"} pt={2}>
              <h2>
                <AccordionButton
                  color={"brand.white"}
                  bg={"brand.lightGrey"}
                  borderRadius={"8px"}
                  _expanded={{ bg: "brand.red" }}
                >
                  <Box as="span" flex="1" textAlign="left">
                    Raydium Repls
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box py={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-6"}
                  >
                    {replits.raydiumRepls.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}&nbsp;<ReplBadge />
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem border={"none"} pt={2}>
              <h2>
                <AccordionButton
                  color={"brand.white"}
                  bg={"brand.lightGrey"}
                  borderRadius={"8px"}
                  _expanded={{ bg: "brand.red" }}
                >
                  <Box as="span" flex="1" textAlign="left">
                    Pump.fun Repls
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box py={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-6"}
                  >
                    {replits.pumpfunRepls.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}&nbsp;<ReplBadge />
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem border={"none"} pt={2}>
              <h2>
                <AccordionButton
                  color={"brand.white"}
                  bg={"brand.lightGrey"}
                  borderRadius={"8px"}
                  _expanded={{ bg: "brand.red" }}
                >
                  <Box as="span" flex="1" textAlign="left">
                    Orca Repls
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box py={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-6"}
                  >
                    {replits.orcaRepls.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}&nbsp;<ReplBadge />
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem border={"none"} pt={2}>
              <h2>
                <AccordionButton
                  color={"brand.white"}
                  bg={"brand.lightGrey"}
                  borderRadius={"8px"}
                  _expanded={{ bg: "brand.red" }}
                >
                  <Box as="span" flex="1" textAlign="left">
                    Misc Repls
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box py={3}>
                  <UnorderedList
                    styleType="none"
                    color={"brand.white"}
                    spacing={"4"}
                    ms={"-6"}
                  >
                    {replits.defaultReps.map((blog) => (
                      <ListItem key={blog.id}>
                        <Link
                          href={blog.blogLink}
                          target="_blank"
                          _hover={{
                            textDecoration: "none",
                            color: "brand.red",
                          }}
                        >
                          <Text lineHeight={"19px"} fontSize={"16px"}>
                            {blog.blogName}&nbsp;{blog.isNew && "🔥"}&nbsp;<ReplBadge />
                          </Text>
                        </Link>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Stack>
      </Container>
    </Stack>
  );
};

const ReplBadge = () => {
  return <Text as="span" bg={"brand.yellow"} color={"brand.black"} fontWeight={"bold"} px={1} fontSize={"0.7rem"} lineHeight={"0"} borderRadius={"2px"}>Repl</Text>
};
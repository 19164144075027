import { Box, Stack,Heading,Image } from "@chakra-ui/react";
import Marquee from "react-fast-marquee";
import RollingLineReverse from "../../common/RollingLineReverse";

export default function ClientRollingSection() {
    return (
        <>
        <Stack as={'div'} backgroundColor={'#111'} height={"64px"} marginBottom={"-35px"} width={"100vw"}>
        </Stack>
        <Stack as={'div'} backgroundColor={'brand.red'} transform={"rotate(2deg)"} marginTop={"-36px"} zIndex={4} paddingTop={"10px"} width={"103vw"} marginLeft={"-8px"}>
                <RollingLineReverse />
                <Stack as={"div"}  marginBottom={"6px"}>
                    <Box as={"div"} padding={"20px 0px 16px"}>
                        <Box paddingBottom={"24px"}>
                            <Heading as={"h2"} textAlign={"center"} fontSize={{base: "3xl", md: "4xl", xl: "5xl"}} color={"#fff"}>
                                Trusted By
                            </Heading>
                        </Box>
                        
                        <Marquee direction="right">
                            {/* <Box as="div" className="rolling_each_image">
                                <Image src="/assets/PartnerLogos/defibia.png" alt="defibia" style={{width:"150px"}} />
                            </Box> */}
                            {/* <Box as="div" className="rolling_each_image">
                                <Image src="/assets/PartnerLogos/jungleCats.png" style={{width:"150px"}} alt="partnerimage"/>
                            </Box> */}
                            {/* <Box as="div" className="rolling_each_image">
                                <Image src="/assets/PartnerLogos/mintFlick.png" style={{width:"130px", marginTop: "6px"}} alt="partnerimage" />
                            </Box> */}
                            {/* <Box as="div" className="rolling_each_image">
                                <Image src="/assets/PartnerLogos/Komoverse_bw.svg" style={{width:"170px"}} alt="partnerimage" />
                            </Box> */}
                            <Box as="div" className="rolling_each_image" paddingTop={"12px"}>
                                <Image src="/assets/PartnerLogos/roundies.png" style={{width:"170px"}} alt="partnerimage"/>
                            </Box>
                            {/* <Box as="div" className="rolling_each_image" paddingTop={"12px"}>
                                <Image src="/assets/PartnerLogos/solvent.png" style={{width:"170px"}} alt="partnerimage" />
                            </Box> */}
                            {/* <Box as="div" className="rolling_each_image">
                                <Image src="/assets/PartnerLogos/moonholdings.png" style={{width:"220px"}} alt="partnerimage"/>
                            </Box> */}
                            <Box as="div" className="rolling_each_image">
                                {/* <Image src="/assets/PartnerLogos/graphqlana.png" style={{width:"190px", marginTop: "4px"}} alt="partnerimage"/> */}
                                <Image src="/assets/PartnerLogos/guacamole.svg" style={{width:"190px", marginTop: "4px"}} alt="guacamole"/>
                            </Box>
                            <Box as="div" className="rolling_each_image">
                                <Image src="/assets/PartnerLogos/decaf-white.png" alt="defibia" style={{width:"150px"}} />
                            </Box>
                            {/* <Box as="div" className="rolling_each_image">
                                <Image src="/assets/PartnerLogos/deCalls-white.png" style={{width:"150px"}} alt="partnerimage"/>
                            </Box> */}
                            <Box as="div" className="rolling_each_image">
                                <Image src="/assets/PartnerLogos/primes-white.png" style={{width:"120px", marginTop: "6px"}} alt="partnerimage" />
                            </Box>
                            <Box as="div" className="rolling_each_image">
                                <Image src="/assets/PartnerLogos/tumiLabs-white.png" style={{width:"170px"}} alt="partnerimage" />
                            </Box>
                            <Box as="div" className="rolling_each_image">
                                <Image src="/assets/PartnerLogos/dscvr.png" style={{width:"170px"}} alt="dscvr image" />
                            </Box>
                            <Box as="div" className="rolling_each_image">
                                {/* <Image src="/assets/PartnerLogos/Vorld.png" style={{width:"210px"}} alt="Vorld" /> */}
                                <Image src="/assets/PartnerLogos/3-land.png" style={{width:"100px"}} alt="3Land" />
                            </Box>
                            <Box as="div" className="rolling_each_image">
                                <Image src="/assets/PartnerLogos/grape.png" style={{width:"150px"}} alt="Grape" />
                            </Box>
                            <Box as="div" className="rolling_each_image">
                                {/* <Image src="/assets/PartnerLogos/pixel-panda.png" style={{width:"100px"}} alt="pixel panda" /> */}
                                <Image src="/assets/PartnerLogos/chingari.png" style={{width:"60px"}} alt="Chingari" />
                            </Box>
                            <Box as="div" className="rolling_each_image">
                                {/* <Image src="/assets/PartnerLogos/ned-finance.png" style={{width:"130px"}} alt="ned finance" /> */}
                                <Image src="/assets/PartnerLogos/gmgn.png" style={{width:"130px"}} alt="gmgn" />
                            </Box>
                            <Box as="div" className="rolling_each_image">
                                {/* <Image src="/assets/PartnerLogos/continent.png" style={{width:"100px"}} alt="continent" /> */}
                                <Image src="/assets/PartnerLogos/neonevm.png" style={{width:"140px"}} alt="neon evm" />
                            </Box>
                            <Box as="div" className="rolling_each_image">
                                {/* <Image src="/assets/PartnerLogos/face-image.png" style={{width:"60px"}} alt="partner image" /> */}
                                <Image src="/assets/PartnerLogos/monaco_protocol.png" style={{width:"60px"}} alt="Monaco Protocol" />
                            </Box>
                            {/* <Box as="div" className="rolling_each_image">
                                <Image src="/assets/PartnerLogos/myro-white.png" style={{width:"160px"}} alt="Myro" />
                            </Box> */}
                            <Box as="div" className="rolling_each_image">
                                <Image src="/assets/PartnerLogos/okay-bears.png" style={{width:"90px"}} alt="Myro" />
                            </Box>
                        </Marquee>
                    </Box>
                </Stack>
                <RollingLineReverse />
            </Stack>
            <Stack as={'div'} backgroundColor={'#111'} height={"64px"} marginTop={"-35px"} width={"100vw"}>
            </Stack>
        </>
    );
}
import { useEffect } from "react";
import ReactGA from "react-ga4";

import MainStyles from "../../resources/css/css.module.css";
import JupStyles from "./jup-styles-css.module.css";
import { Box, Container, Heading, Text, Button } from "@chakra-ui/react";
import JupMaps from "../LandingPage/LandingPageComponents/JupMaps";

const Jupapismain = () => {
  useEffect(() => {
    document.title = "Solana Jupiter V6 Swap API - SHYFT";
    ReactGA.send({
      hitType: "pageview",
      page: "/solana-jupiter-apis",
    });
  }, []);
  return (
    <>
      <Box as="section" className={`${JupStyles.hero}`}>
        <Container maxW={"7xl"}>
          <Heading as="h1" color={"white"}>
            Region-Optimized{" "}
            <Text as="span" style={{ color: "#ef4444" }}>
              Jupiter V6
            </Text>{" "}
            Swap API
          </Heading>
          <Text as="p">
            Experience lightning-fast token swaps with our region-specific endpoints. Built for developers who need fast and reliable interaction with Jupiter Dex.
          </Text>
          <Box as="div" className={`${JupStyles.button_group}`}>
            <Button
              as={"a"}
              href="https://discord.gg/8JyZCjRPmr"
              target="_blank"
              pt={"12px"}
              pb={"10px"}
              display={"block"}
              size={"sm"}
              // width={"28"}
              height={"10"}
              // maxW={"180px"}
              w={{ base: "full", lg: "160px" }}
              border={"1px solid"}
              borderColor={"brand.red"}
              bgColor={"brand.red"}
              color={"brand.white"}
              borderRadius={"full"}
              textColor={"#fff"}
              fontFamily={"heading"}
              fontWeight={"500"}
              _hover={{
                bgColor: "brand.white",
                color: "brand.red",
                borderColor: "brand.white",
                textDecoration: "none",
              }}
              mt={{ base: 2, md: 1, lg: 0 }}
              textAlign={"center"}
            >
              Start Free Trial
            </Button>
            <Button
              as={"a"}
              href="https://station.jup.ag/docs/apis/swap-api"
              target="_blank"
              pt={"12px"}
              pb={"10px"}
              display={"block"}
              size={"sm"}
              // width={"28"}
              height={"10"}
              // maxW={"300px"}
              w={{ base: "full", lg: "210px" }}
              border={"1px solid"}
              borderColor={"brand.red"}
              bgColor={"brand.black"}
              color={"brand.white"}
              borderRadius={"full"}
              textColor={"#fff"}
              fontFamily={"heading"}
              fontWeight={"500"}
              _hover={{
                bgColor: "brand.red",
                color: "brand.white",
                textDecoration: "none",
              }}
              mt={{ base: 2, md: 1, lg: 0 }}
              textAlign={"center"}
            >
              View Documentation
            </Button>
          </Box>
        </Container>
      </Box>

      <Box as="section">
            <JupMaps />
        </Box>

      {/* <!-- Features Section --> */}
      <Box as="section" className={JupStyles.features}>
            <Container maxW={"7xl"}>
                <Heading as="h2" className={JupStyles.section_title} textColor={"brand.white"}>Advanced DEX Aggregation Infrastructure</Heading>
                <Text as="p" className={JupStyles.section_description}>Get the most efficient token swaps with our optimized infrastructure and region-specific endpoints.</Text>
                
                <Box as="div" className={JupStyles.features_grid}>
                    <Box as="div" className={JupStyles.feature_card}>
                        <Box as="div" className={JupStyles.feature_icon}>🌐</Box>
                        <Heading as="h3" textColor={"brand.white"}>Region-Optimized</Heading>
                        <Text as="p">Connect to endpoints closest to your users and servers. New York, Ashburn, Amsterdam and Frankfurt.
                        </Text>
                    </Box>
                    <Box as="div" className={JupStyles.feature_card}>
                        <Box as="div" className={JupStyles.feature_icon}>⚡</Box>
                        <Heading as="h3" textColor={"brand.white"}>High Performance</Heading>
                        <Text as="p">150 requests per second with unlimited monthly calls.</Text>
                    </Box>
                    <Box as="div"  className={JupStyles.feature_card}>
                        <Box as="div" className={JupStyles.feature_icon}>🛡️</Box>
                        <Heading as="h3" textColor={"brand.white"}>No Credit System</Heading>
                        <Text as="p">Simple, transparent pricing with no usage limits or credit system.</Text>
                    </Box>
                </Box>
            </Container>
        </Box>
        

        {/* <!-- Pricing Section --> */}
        <Box as="section" className={JupStyles.pricing}>
            <Container maxW={"7xl"}>
                <Heading as="h2" className={JupStyles.section_title} style={{ color: "#fff" }}>Simple, Transparent Pricing</Heading>
                <Box as="div" className={JupStyles.pricing_card}>
                    <Box className={JupStyles.price} color={"brand.white"}>$299<Text as="span">/month</Text></Box>
                    <ul className={JupStyles.features_list}>
                        <li>150 req/second capability</li>
                        <li>No monthly call limits</li>
                        <li>Region-specific routing</li>
                        {/* <li>150 req/sec</li> */}
                    </ul>
                    <Button
                        as={"a"}
                        href="https://discord.gg/8JyZCjRPmr"
                        target="_blank"
                        pt={"12px"}
                        pb={"10px"}
                        display={"block"}
                        size={"sm"}
                        // width={"28"}
                        height={"10"}
                        // maxW={"180px"}
                        margin={"0 auto"}
                        w={{ base: "full", lg: "210px" }}
                        border={"1px solid"}
                        borderColor={"brand.red"}
                        bgColor={"brand.red"}
                        color={"brand.white"}
                        borderRadius={"full"}
                        textColor={"#fff"}
                        fontFamily={"heading"}
                        fontWeight={"500"}
                        _hover={{
                            bgColor: "brand.white",
                            color: "brand.red",
                            borderColor: "brand.white",
                            textDecoration: "none",
                        }}
                        mt={{ base: 2, md: 1, lg: 0 }}
                        textAlign={"center"}
                    >
                        Start Free Trial
                    </Button>
                </Box>
            </Container>
        </Box>

      
    </>
  );
};

export default Jupapismain;
